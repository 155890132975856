import React from "react";
import '../css/Rodape.css';
import logo from '../../img/Logo/Col4bore logo 3.png'
import { useNavigate } from 'react-router-dom';

function Rodape() {

    const navigate = useNavigate();

    const GoToAS = () => {
        navigate('/agendar-servicos');
    }

    const GoToAD = () => {
        navigate('/acompanhar-demandas');
    }

    const GoToBI = () => {
        navigate('/bairro-inteligente');
    }

    const GoToRP = () => {
        navigate('/relatos-de-problemas');
    }

    return (
        <div className="footer">
            <div className="rodape">
                <div>
                    <div>
                        <div>
                            <img src={logo} alt="Logo"/>
                            <h2>Col4bore</h2>
                        </div>
                        <p>
                        (34) 99868-2292
                        <br />
                        col4bore@gmail.com
                        </p>
                    </div>
                    <div>
                        <h2>Menu</h2>
                        <div>
                            <span>
                                <button onClick={GoToAS}>Agendar Serviços</button>
                            </span>
                            <span>
                                <button onClick={GoToAD}>Acompanhar Demandas</button>
                            </span>
                        </div>
                        <div>
                            <span>
                                <button onClick={GoToBI}>Bairro Inteligente</button>
                            </span>
                            <span>
                                <button onClick={GoToRP}>Relatos de Problemas</button>
                            </span>
                        </div>
                    </div>
                    <div>
                        <h2>Redes Sociais</h2>
                        <div>
                            <span>
                                <a href="https://x.com/col4bore" target="_blank" rel="noopener noreferrer">
                                    <button>Twitter</button>
                                </a>
                            </span>
                            <span>
                                <a href="https://www.youtube.com/@Col4bore/" target="_blank" rel="noopener noreferrer">
                                    <button>YouTube</button>
                                </a>
                            </span>
                        </div>
                        <div>
                            <span>
                            <a href="https://www.instagram.com/col4bore" target="_blank" rel="noopener noreferrer">
                                    <button>Instagram</button>
                                </a>
                            </span>
                            {/* <span>
                                <a href="https://www.linkedin.com/in/col4bore" target="_blank">
                                    <button>LInkedln</button>
                                </a>
                            </span> */}
                            
                        </div>
                    </div>
                </div>
                <div>
                    {/* <div>
                        <button></button>
                        <button></button>
                        <button></button>
                    </div> */}
                    <div className="dirReserv">
                        <p>2024 Col4bore. Todos os Direitos Reservados</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Rodape;