import React, { useState } from "react";
import '../css/Parceiros.css';
import SideScroll from './SideScrollList';

function Parceiros({ items }) {

  const [controlForm, setControlForm] = useState(false)

  const [emailValues, setEmailValues] = useState({
    nome: '',
    email: '',
    texto: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailValues({ ...emailValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const buttonEnvForm = document.getElementById('buttonEnvForm');
    
    setControlForm(true)
    
    buttonEnvForm.setAttribute('disabled', controlForm)

    try {
      const response = await fetch('https://ec2.col4bore.com.br/api/email/send-email', {
      // const response = await fetch('http://localhost:3001/api/email/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailValues.email, message: emailValues.texto}),
      });

      if (response.ok) {
        alert('Email enviado com sucesso!');

        setControlForm(false)
        
        buttonEnvForm.removeAttribute('disabled')

        setEmailValues({ nome: '', email: '', texto: '' });
      } else {
        alert('Falha ao enviar o email. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao enviar o email:', error);
      alert('Erro ao enviar o email. Tente novamente.');
    }
  };

  return (
    <div className='parceiros'>
      <div>
        <button type='button'>Parceiros</button>
      </div>
      <div>
        <SideScroll items={items} />
      </div>
      <div className='sugestao'>
        <form onSubmit={handleSubmit}>
          <h1>Deixe sua mensagem</h1>
          <div>
            <input
              type="text"
              name="nome"
              placeholder="Nome"
              value={emailValues.nome}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Seu melhor e-mail"
              value={emailValues.email}
              onChange={handleInputChange}
            />
            <button id="buttonEnvForm" type="submit">Enviar</button>
          </div>
          <br />
          <textarea
            name="texto"
            placeholder="Mensagem..."
            value={emailValues.texto}
            onChange={handleInputChange}
          />
        </form>
        <p>
          Sua opinião é valiosa para nós! Use o formulário acima para compartilhar suas
          experiências, sugestões ou relatar problemas
          que você encontrou.
        </p>
      </div>
    </div>
  );
}

export default Parceiros;
