import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import img1 from '../../img/Home/image 01 3.png';
import '../css/Cadastro.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu';
import Rodape from "./Rodape";
import ScrollToTopOnMount from './ScrollToTopOnMount';
import ModalAviso from './ModalP';
import CadRealizado from './CadRealizado'
// import emailjs from 'emailjs-com';

function Cadastro({ backendUrl }) {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    
    const closeModal = () => setModalIsOpen(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [lvlSenha, setLvlSenha] = useState('Senha fraca');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const botCadastreSe = async (event) => {
        event.preventDefault();

        const campoObrig = document.getElementById('campoObrig');
        const senhasDiff = document.getElementById('senhasDiff');
        const emailsDiff = document.getElementById('emailsDiff');
        let errorCount = 0;
        const b1 = document.getElementById('b1');
        const b2 = document.getElementById('b2');
        const b3 = document.getElementById('b3');
        const resp = document.getElementById('respC');
        const respCont = document.getElementById('respCont');

        const filhosB1 = b1.querySelectorAll('*');
        const filhosB2 = b2.querySelectorAll('*');
        const filhosB3 = b3.querySelectorAll('input');

        const ids = [];

        filhosB1.forEach(filhos => {
            ids.push(filhos.id);
        });
        filhosB2.forEach(filhos => {
            ids.push(filhos.id);
        });
        filhosB3.forEach(filhos => {
            ids.push(filhos.id);
        });

        // console.log(ids);


        for (let i = 0; i < ids.length; i++) {
            const element = document.getElementById(ids[i]);
            if (element.value === '') {
                element.style.border = '1px solid red';
                errorCount++;
            } else {
                element.style.border = 'none';
            }
        }

        if (!resp.checked) {
            respCont.style.color = 'red';
        } else {
            respCont.style.color = '#666666';
        }

        const senha = document.getElementById(ids[12]).value
        const confSenha = document.getElementById(ids[13]).value
        // console.log(senha, confSenha);

        if (senha !== confSenha) {
            senhasDiff.style.display = 'block';
            // errorCount++;
        } else {
            senhasDiff.style.display = 'none';
        }

        console.log(ids);

        const email = document.getElementById(ids[2]).value;
        const confEmail = document.getElementById(ids[3]).value;

        if (email !== confEmail) {
            emailsDiff.style.display = 'block';
            // errorCount++;
        } else {
            emailsDiff.style.display = 'none';
        }

        if (errorCount === 0) {
            campoObrig.style.display = 'none'
            handleSubmit();
        } else {
            campoObrig.style.display = 'block';
        }
    };

    const { state } = useLocation();

    const [formValues, setFormValues] = useState({
        nome: '',
        email: '',
        cpf: '',
        cep: '',
        senha: '',
        endereco: '',
        num: '',
        comp: '',
        cidade: '',
        bairro: '',
        estado: '',
        tel: ''
    });
    const [cpfError, setCpfError] = useState('');

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        let formattedValue = value;

        if (name === 'cpf') {
            const rawValue = value.replace(/\D/g, ''); // Remove formatação
            const isValid = validaCPF(rawValue);
            if (!isValid) {
                setCpfError('CPF inválido');
            } else {
                setCpfError('');
            }

            formattedValue = formatCPF(value); // Adiciona formatação para visualização
            setFormValues({
                ...formValues,
                [name]: rawValue, // Armazena sem formatação
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: formattedValue,
            });
        }

        if (name === 'cep' && value.length === 8) {
            const addressData = await fetchAddressData(value);
            if (addressData) {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    endereco: addressData.endereco,
                    pais: addressData.pais,
                    cidade: addressData.cidade,
                    bairro: addressData.bairro,
                    estado: addressData.estado
                }));
            }
        }
    };

    const validaCPF = (cpf) => {
        cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
        if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

        let soma = 0;
        for (let i = 0; i < 9; i++) {
            soma += parseInt(cpf.charAt(i)) * (10 - i);
        }

        let resto = 11 - (soma % 11);
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(9))) return false;

        soma = 0;
        for (let i = 0; i < 10; i++) {
            soma += parseInt(cpf.charAt(i)) * (11 - i);
        }

        resto = 11 - (soma % 11);
        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.charAt(10))) return false;

        return true;
    };

    const formatCPF = (cpf) => {
        cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
        cpf = cpf.replace(/^(\d{3})(\d)/, '$1.$2'); // Coloca um ponto depois dos primeiros 3 dígitos
        cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca um ponto depois dos próximos 3 dígitos
        cpf = cpf.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4'); // Coloca um hífen depois dos próximos 3 dígitos
        return cpf;
    };

    useState(() => {
        if (state) {
            // console.log('aquiii',state)
            setFormValues((prevValues) => ({
                ...prevValues,
                ...state,
            }));
        }
    }, [state]);

    const fetchAddressData = async (cep) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();
            if (!data.erro) {
                const addressData = {
                    endereco: `${data.logradouro}`,
                    pais: 'Brasil',
                    cidade: data.localidade,
                    bairro: data.bairro,
                    estado: data.uf
                };
                return addressData;
            } else {
                console.error('CEP não encontrado');
                return null;
            }
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
            return null;
        }
    };

    const atualizarForcaSenha = (senha) => {
        let forca = 0;
        let faltaMinimo = true;
        let faltaMaiuscula = true;
        let faltaEspecial = true;

        // Verifica se a senha possui pelo menos 10 caracteres
        if (senha.length >= 10) {
            forca += 1;
            faltaMinimo = false;
        }

        // Verifica se a senha possui pelo menos uma letra maiúscula
        if (/[A-Z]/.test(senha) && /[a-z]/.test(senha)) {
            forca += 1;
            faltaMaiuscula = false;
        }

        // Verifica se a senha possui pelo menos um caractere especial
        if (/[\W_]/.test(senha)) {
            forca += 1;
            faltaEspecial = false;
        }

        // Calcula a porcentagem de conclusão com base nos requisitos atendidos
        const porcentagem = (forca / 3) * 100;
        console.log(porcentagem);

        // Atualiza a largura da barra de força da senha
        const forcaBarra = document.getElementById('forca-senha');
        forcaBarra.style.width = `${porcentagem}%`;

        // Adiciona ou remove os spans dinamicamente
        const faltaMinimoSpan = document.getElementById('falta-minimo');
        const faltaMaiusculaSpan = document.getElementById('falta-letra');
        const faltaEspecialSpan = document.getElementById('falta-especial');

        if (faltaMinimo) {
            if (!faltaMinimoSpan) {
                document.getElementById('mensagem-forca').innerHTML += '<p id="falta-minimo">A senha deve ter no mínimo 10 caracteres.</p>';
            }
        } else {
            if (faltaMinimoSpan) {
                faltaMinimoSpan.remove();
            }
        }

        if (faltaMaiuscula) {
            if (!faltaMaiusculaSpan) {
                document.getElementById('mensagem-forca').innerHTML += '<p id="falta-letra">A senha deve ter pelo menos uma letra minúscula e maiúscula.</p>';
            }
        } else {
            if (faltaMaiusculaSpan) {
                faltaMaiusculaSpan.remove();
            }
        }

        if (faltaEspecial) {
            if (!faltaEspecialSpan) {
                document.getElementById('mensagem-forca').innerHTML += '<p id="falta-especial">A senha deve ter pelo menos um caractere especial.</p>';
            }
        } else {
            if (faltaEspecialSpan) {
                faltaEspecialSpan.remove();
            }
        }

        // Valida a senha de acordo com os requisitos
        if (forca === 1) {
            setLvlSenha('Senha fraca');
            forcaBarra.style.backgroundColor = 'red';
        } else if (forca === 2) {
            setLvlSenha('Senha média');
            forcaBarra.style.backgroundColor = 'yellow';
        } else if (forca === 3) {
            forcaBarra.style.backgroundColor = 'green';
            setLvlSenha('Senha forte');
            return true;
        } else {
            setLvlSenha('Senha fraca');
            return false;
        }
    };



    const handleSubmit = async () => {
        try {
            const response = await fetch(`https://ec2.col4bore.com.br/perfil/register`, {
            // const response = await fetch(`http://localhost:3001/perfil/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formValues)
            });

            if (response.ok) {
                const email = formValues.email;
                if (validateEmail(email)) {
                    // alert('Cadastro realizado com sucesso! Verifique seu e-mail para confirmar seu cadastro.');
                    openModal();
                    
                } else {
                    alert('Erro: E-mail inválido. Por favor, verifique seu e-mail e tente novamente.');
                }
            } else {
                alert('Erro ao cadastrar usuário: ' + response.statusText);
            }
        } catch (error) {
            alert('Erro ao cadastrar usuário: ' + error.message);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    return (
        <div className='TelaCadastro'>
            <ModalAviso isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Cadastro concluído" titulo={'Cadastro concluído'} children={<CadRealizado />} close={false}/>
            <ScrollToTopOnMount />
            <Menu backendUrl={backendUrl} />
            <div className="contentCadastro">
                <div>
                    <div>
                        <form className="cadastro">
                            <h1>Cadastre</h1>

                            <div className="group">
                                <div className="b1" id="b1">
                                    <input type="text" placeholder='Nome Completo' name="nome" id="nomeC" value={formValues.nome} onChange={handleInputChange} maxLength={100} required/>

                                    <input type="text" placeholder='CPF' name="cpf" id="cpfC" value={formatCPF(formValues.cpf)} onChange={handleInputChange} maxLength={14} required/> {cpfError && <span style={{ color: 'red' }}>{cpfError}</span>}

                                    <input type="email" placeholder='Email' name="email" id="emailC" value={formValues.email} onChange={handleInputChange} maxLength={100} required/>

                                    <input type="email" placeholder='Confirme seu email' name="confEmail" id="confEmailC" maxLength={100} required/>

                                    <input type="tel" placeholder='Telefone' name="tel" id="telC" value={formValues.tel} onChange={handleInputChange} maxLength={15} required/>

                                    <input type="text" placeholder='CEP' name="cep" id="cepC" value={formValues.cep} onChange={handleInputChange} maxLength={9} required/>
                                </div>
                                <div className="b2" id="b2">
                                    <input type="text" placeholder='Endereço' name="endereco" id="endC" value={formValues.endereco} onChange={handleInputChange} maxLength={100} required/>

                                    <input type="text" placeholder='Número' name="num" id="numC" value={formValues.num} onChange={handleInputChange} maxLength={10} required/>

                                    <input type="text" placeholder='Complemento' name="comp" id="compC" value={formValues.comp} onChange={handleInputChange} maxLength={50}/>

                                    <input type="text" placeholder='Bairro' name="bairro" id="bairoC" value={formValues.bairro} onChange={handleInputChange} maxLength={50} required/>

                                    <input type="text" placeholder='Cidade' name="cidade" id="cidadeC" value={formValues.cidade} onChange={handleInputChange} maxLength={50} required/>

                                    <input type="text" placeholder='Estado' name="estado" id="estadoC" value={formValues.estado} onChange={handleInputChange} maxLength={2} required pattern="[A-Z]{2}" />
                                </div>
                                
                                <div className="b3" id="b3">

                                    <div>
                                        <input type={showPassword ? 'text' : 'password'} placeholder="Senha" name="senha" id="senhaC" value={formValues.senha} onChange={(e) => { const senha = e.target.value; atualizarForcaSenha(senha); handleInputChange(e); }} />
                                        <button
                                            type="button"
                                            className="toggle-password"
                                            onClick={togglePasswordVisibility}
                                        >
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                    </div>
                                    <div>
                                        <input type={showPassword2 ? 'text' : 'password'} placeholder='Confirme sua senha' name="senha2" id="senha2C" />
                                        <button
                                            type="button"
                                            className="toggle-password"
                                            onClick={togglePasswordVisibility2}
                                        >
                                            <FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} />
                                        </button>
                                    </div>

                                    <div id="barraLvlSenha" style={{ width: '90%', height: '5px', backgroundColor: 'gray', display: 'flex', flexDirection: 'column' }}>
                                        <div id="forca-senha" style={{ width: '0%', height: '5px', backgroundColor: 'red' }}></div>
                                    </div>
                                    <div id="txtLvlSenha">
                                        <p>{lvlSenha}</p>
                                    </div>
                                    <div id="txtReqSenha" style={{ width: '90%', display: 'flex', flexDirection: 'column' }}>
                                        <div id="mensagem-forca"></div>
                                    </div>

                                </div>
                            </div>

                            <div className="group2">
                                <div>
                                    <input type="checkbox" name="resp" id="respC" className="resp" />
                                    <label htmlFor="respC" id="respCont">
                                        Declaro que as informações acima prestadas são verdadeiras, e assumo a inteira responsabilidade pelas mesmas.
                                    </label>
                                </div>
                                <div>
                                    <label id="campoObrig">
                                        Este(s) campo(s) é(são) obrigatório(s).
                                    </label>
                                    <label id="senhasDiff">
                                        As senhas devem ser iguais.
                                    </label>
                                    <label id="emailsDiff">
                                        Os emails devem ser iguais.
                                    </label>
                                </div>
                            </div>

                            <button type="button" onClick={botCadastreSe}>Cadastrar</button>
                        </form>
                    </div>
                </div>
                <div>
                    <img src={img1} alt="" />
                </div>
            </div>
            <Rodape />
        </div>
    );
}

export default Cadastro;
