import React, { useRef } from 'react';
import '../css/SideScrollList.css';

const Carousel = ({ items }) => {
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -250,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: 250,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="carousel-wrapper">
      <button className="carousel-button" onClick={scrollLeft}>&lt;</button>
      <div className="carousel-viewport" ref={carouselRef}>
        <div className="carousel">
          {items.map((item, index) => (
            <div className="carousel-item" key={item.id || index}>
              <img src={item.content} alt="Logo"/>
            </div>
          ))}
        </div>
      </div>
      <button className="carousel-button" onClick={scrollRight}>&gt;</button>
    </div>
  );
};

export default Carousel;
