import React from 'react';
import '../css/Home.css';
// import img1 from '../../img/Home/image 01 3.png';
import icon1 from '../../img/Home/Icons/icon1.png';
import icon2 from '../../img/Home/Icons/icon2.png';
import icon3 from '../../img/Home/Icons/icon3.png';
import icon4 from '../../img/Home/Icons/icon4.png';
import img2 from '../../img/Home/image 04 1.png';
import Parceiros from './Parceiros';
import Menu from './Menu';
import Rodape from './Rodape';
import ScrollToTopOnMount from './ScrollToTopOnMount';
// import YouTube from 'react-youtube';

function Home({ items, logged, updateLogged, backendUrl }) {

  // const videoId = 'https://www.youtube.com/watch?v=jE-IZWbHD-I'; // Substitua pelo ID do seu vídeo

  // const opts = {
  //   height: '390',
  //   width: '640',
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 1,
  //   },
  // };

    return (
      <div className="App">
        <ScrollToTopOnMount />
        <Menu logged={logged} updateLogged={updateLogged} backendUrl={backendUrl}/>
        <div className="TelaHome">
            <div className='meio'>
              <div>
                <div>
                  <h1>O que voce pode fazer na Col4bore:</h1>
                  <p>
                    Cadastre-se e compartilhe suas preocupações:
                    Registre-se na plataforma e compartilhe suas preocupações e ideias para melhorar o seu bairro.
                    Seu feedback é fundamental para identificar os problemas e encontrar soluções.
                  </p>
                  <p>
                    Participe de discussões e votações:
                    Junte-se a discussões em andamento sobre questões locais e vote nas propostas que você considera mais importantes.
                    Sua opinião conta!
                  </p>
                  <p>
                    Envolva-se em projetos comunitários:
                    Participe de projetos e iniciativas comunitárias para melhorar a qualidade de vida em seu bairro.
                    Seja voluntário e faça a diferença!
                  </p>
                  <p>
                    Conecte-se com outros moradores:
                    Conheça seus vizinhos, forme grupos de interesse e organize eventos locais.
                    Juntos, podemos construir uma comunidade mais forte e unida.
                  </p>
                </div>
                <div>
                  <img src={img2} alt="Img2"/>
                </div>
              </div>
              <div>
                <div className='grid-cards'>
                  <div className="services-section">
                    <img src={icon1} alt="Logo"/>
                    <h2>AGENDAR SERVIÇOS</h2>
                    <p>
                      A agenda de serviços públicos permite aos usuários agendar
                      consultas médicas, vacinações, coleta de lixo e outros serviços
                      oferecidos pela prefeitura.
                    </p>
                  </div>
                  <div className="demands-section">
                    <img src={icon2} alt="Logo"/>
                    <h2>ACOMPANHAR DEMANDAS</h2>
                    <p>
                      A seção de acompanhamento de demandas oferece aos usuários uma
                      visão geral de todas as solicitações que eles fizeram e seu status
                      atual.
                    </p>
                  </div>
                  <div className="intelligent-neighborhood-section">
                    <img src={icon3} alt="Logo"/>
                    <h2>BAIRRO INTELIGENTE</h2>
                    <p>
                      O recurso de "Bairro Inteligente" fornece aos usuários informações
                      úteis sobre eventos locais, pontos de interesse e serviços
                      disponíveis em sua área.
                    </p>
                  </div>
                  <div className="report-problems-section">
                    <img src={icon4} alt="Logo"/> 
                    <h2>RELATO DE PROBLEMAS</h2>
                    <p>
                      Ao selecionar a opção de relatar um problema, os usuários são
                      guiados por um processo simples e direto.
                    </p>
                  </div>
                </div>
                <div>
                  <button type='button'>Nos Ajude A Melhorar!</button>
                  <p>
                    De seu feedback sobre as funcionalidades da ferramenta ou
                    sobre como podemos ajudar ainda mais vocês e suas
                    comunidades na área de Feedback/avaliação ou seu
                    feedback sobre as demandas na área Acompanhar
                    demandas.
                  </p>
                  <p>
                    Além disso sua participação na comunidade colaborativa faz
                    com vocês possam se conectar, compartilhar informações
                    e se unirem para resolver problemas locais.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="inicio">
              <div>
                <h1>Quem somos?</h1>
                <h4>Col4bore é uma comunidade colaborativa, inclusiva, sustentável e inteligente no físico e digital.</h4>
                <p>
                  Na Col4bore, acreditamos no poder da colaboração e da comunidade para resolver problemas locais.
                  Somos uma plataforma que conecta moradores, líderes comunitários e autoridades locais para identificar,
                  discutir e resolver os desafios enfrentados pelos bairros.
                </p>
                <button type='button'>Col4bore!</button>
              </div>
              <div>
                <img src={img1} alt="Logo"/>
              </div>
            </div> */}
            
            
            <div className='final'>
              <div>
                <button type='button'>Venha col4borar você também!</button>
              </div>
              <div>
                {/* <video controls> */}
                  {/* <source  src='../../../vid/Você Sabía.mp4' type='video/mp4'/> */}
                  <iframe className='video' src="https://www.youtube.com/embed/jE-IZWbHD-I?si=aUPINPXpVW5wnFKf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  {/* <YouTube videoId={videoId} opts={opts} /> */}
                {/* </video> */}
              </div>
            </div>
            
            <div className='contParceiros'>
              <Parceiros items={items} />
            </div>
        </div>
        <Rodape />
      </div>
    );
}

export default Home;