import React from "react";
import '../css/MsgEventoCriado.css'

function MsgEventoCriado() {

    const GoToPerfil = () => {
        window.location.reload()
    }

    return (
        <div className="telaMsgEventoCriado">
            <div>
                <p>Evento criado com sucesso!</p>
                <button onClick={GoToPerfil}>Perfil</button>
            </div>
        </div>
    );
};

export default MsgEventoCriado;