import React from "react";
import '../css/TabelaEventos.css'
import abrir from '../../img/Icons/Perfil/abrir.png';
import ModalVerEvento from './Modal'
import DetalhesEvento from './DetalhesEvento'
import { useState } from "react";

function TabelaEventos({ eventos }) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedEvento, setSelectedEvento] = useState(null);

    const openModal = (evento) => {
        setSelectedEvento(evento);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedEvento(null);
    };

    const correctDate = (item) => {
        const data = item.split('T');
        const dataSplit = data[0].split('-');
        const dataF = [dataSplit[2], dataSplit[1], dataSplit[0]].join('/');
        // const dataF = data[0]

        return dataF;
    }

    const CheckStatus = (status) => {

        let resp

        if (status === 'autorizado') {
            resp = 'Autorizado';
        } else if (status === 'em_analise') {
            resp = 'Em análise';
        }
        else if (status === 'recusado') {
            resp = 'Recusado';
        }
        else if (status === 'nao_avaliado') {
            resp = 'Não avaliado';
        }

        return resp
    }

    return (
        <div className="tabelaEventos">
            <table>
                <tr>
                    <th>Título</th>
                    <th className="dataTabEvento">Data</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                {eventos.map((item, index) => (
                    <tr className={item.stats} key={item.EventoID || index}>
                        <td>{item.Titulo}</td>
                        <td className="dataTabEvento">{correctDate(item.data)}</td>
                        <td>{CheckStatus(item.stats)}</td>
                        <td><button onClick={() => openModal(item)}><img src={abrir} alt="See" /></button></td>
                    </tr>
                ))}
            </table>
            {selectedEvento &&
                <ModalVerEvento isOpen={modalIsOpen} onRequestClose={closeModal} 
                contentLabel="Visualizar Evento" titulo={'Visualizar Evento'} children={<DetalhesEvento evento={selectedEvento} route={'contaAdm'}/>}/>
            }
        </div>
    );
};

export default TabelaEventos;