import React, { useState, useEffect, useCallback } from 'react';
import '../css/Dia.css';
import ModalGeral from './Modal';
import ListEventos from './ListEventos';

const Dia = ({ dia, eventos }) => {
    const [modalIsOpen3, setModalIsOpen3] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
    }, [modalIsOpen3]);

    const openModal3 = useCallback(() => {
        setModalIsOpen3(true);
        setButtonDisabled(true);
    }, []);

    const closeModal3 = useCallback(() => {
        setModalIsOpen3(false);
        setButtonDisabled(false);
    }, []);

    const handleClick = useCallback(() => {
        if (eventos && eventos.length > 0) {
            openModal3();
        }
    }, [eventos, openModal3]);

    const renderEventos = () => {
        if (!eventos || eventos.length === 0) return null;

        return (
            <div className="eventos-container">
                {eventos.map((evento, index) => (
                    <div key={index} className="evento-circle" title={evento.nome}></div>
                ))}
            </div>
        );
    };

    return (
        <button 
            type='button'
            className={`day ${eventos && eventos.length > 0 ? 'event' : ''}`} 
            onClick={() => {
                handleClick();
            }}
            disabled={buttonDisabled}
        >
            <ModalGeral 
                isOpen={modalIsOpen3} 
                onRequestClose={closeModal3} 
                contentLabel="Eventos do dia" 
                titulo="Eventos do dia"
            >
                <ListEventos eventos={eventos} />
            </ModalGeral>
            <p className='fixed'>
                {dia}
            </p>
            {renderEventos()}
        </button>
    );
};

export default Dia;
