import React, {useState, useEffect} from "react";
import '../css/AgendarServ.css';
import Menu from './Menu';
import Rodape from './Rodape';
import Parceiros from './Parceiros';
import img1 from '../../img/AS/image 02 1.png';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import ModalBlock from './ModalP';
// import ModalInds from './ModalP';
import UsrNaoLogado from './UsrNaoLogado'
// import ServIndisponivel from './ServIndisponivel';

function AgendarServ({items, logged, updateLogged, backendUrl}) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    // const [modalIsOpen2, setModalIsOpen2] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    // const openModal2 = () => setModalIsOpen2(true);
    // const closeModal2 = () => setModalIsOpen2(false);


    useEffect(() => {
        if (logged === false) {
            openModal();
        }
        // else {
        //     openModal2();
        // }
    }, [logged]);

    return (
        <div className="TelaAS">
            <ModalBlock isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Usuário não logado" titulo={'Usuário não logado'} children={<UsrNaoLogado />} close={false}/>
            {/* <ModalInds isOpen={modalIsOpen2} onRequestClose={closeModal2} contentLabel="Serviço Indisponível no Momento" titulo={'Serviço Indisponível no Momento'} children={<ServIndisponivel />} close={false}/> */}
            <ScrollToTopOnMount />
            <Menu logged={logged} updateLogged={updateLogged} backendUrl={backendUrl}/>
            <div className="contAS">
                <div className="inicioAS">
                    <div>
                        <form>
                            <h1>Como podemos ajudar?</h1>

                            <select name="problema" id="problema">
                                <option value={null}>Escolha</option>
                            </select>
                            <input type="date" name="data" id="data" />
                            <div>
                                <input type="time" name="hora" id="hora" />
                                <button>Verificar Disponibilidade</button>
                            </div>
                        </form>
                        <div>
                            <button type="submit">Enviar</button>
                        </div>
                    </div>
                    <div>
                        <img src={img1} alt="imagem1" />
                    </div>
                </div>
                <div className="ASparceiros">
                    <Parceiros items={items}/>
                </div>
            </div>
            <Rodape />
        </div>
    );
};

export default AgendarServ;