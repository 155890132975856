import React from "react";
import '../css/TabelaDemandas.css'
import abrir from '../../img/Icons/Perfil/abrir.png';
import ModalVerDemanda from './Modal'
import DetalhesDemanda from './DetalhesDemanda'
import { useState } from "react";

function TabelaDemandas({ demandas, route }) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDemanda, setSelectedDemanda] = useState(null);

    const openModal = (demanda) => {
        setSelectedDemanda(demanda);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedDemanda(null);
    };

    const CheckStatus = (status) => {

        let resp

        if (status === 'atendido') {
            resp = 'Atendido';
        } else if (status === 'em_atendimento') {
            resp = 'Em atendimento';
        }
        else if (status === 'recusado') {
            resp = 'Recusado';
        }
        else if (status === 'nao_avaliado') {
            resp = 'Não avaliado';
        }

        return resp
    }

    // Filtra as demandas de acordo com os critérios
    // const demandasFiltradas = demandas.filter(item => item.Cidade !== 'Ituiutaba' && item.Stats !== 'nao_avaliado');

    console.log(demandas);


    return (
        <div className="tabelaDemandas">
            <table>
                <tr>
                    <th>Demandas</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                {demandas.map((item, index) => (
                    <tr className={item.Stats} key={item.DemandaID || index}>
                        <td>{item.Titulo}</td>
                        <td>{CheckStatus(item.Stats)}</td>
                        <td><button onClick={() => openModal(item)}><img src={abrir} alt="See" /></button></td>
                    </tr>
                ))}
            </table>
            {selectedDemanda &&
                <ModalVerDemanda isOpen={modalIsOpen} onRequestClose={closeModal}
                    contentLabel="Visualizar Demanda" titulo={'Visualizar Demanda'}
                    children={<DetalhesDemanda demanda={selectedDemanda} route={route} />}></ModalVerDemanda>
            }
        </div>
    );
};

export default TabelaDemandas;