import React, { useState, useEffect } from "react";
import '../css/AcompDemandas.css';
import Menu from './Menu';
import Rodape from './Rodape';
import img1 from '../../img/AD/image 03 1.png';
import TabelaDemandas from "./TabelaDemandas";
import Parceiros from './Parceiros';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import ModalBlock from './ModalP';
import UsrNaoLogado from './UsrNaoLogado';
// import ServIndisponivel from './ServIndisponivel';

function AcompDemandas({ items, logged, updateLogged, backendUrl }) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    // const [modalIsOpen2, setModalIsOpen2] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    // const openModal2 = () => setModalIsOpen2(true);
    // const closeModal2 = () => setModalIsOpen2(false);

    const [demandas, setDemandas] = useState([]);
    const [demandasFiltradas, setDemandasFiltradas] = useState([]);
    const [cidade, setCidade] = useState("");
    const [bairro, setBairro] = useState("");
    const [cidades, setCidades] = useState([]);
    const [bairros, setBairros] = useState([]);

    const handleCidadeChange = (event) => {
        const cidadeSelecionadaId = parseInt(event.target.value);
        const cidadeSelecionada = cidades.find(cidade => cidade.id === cidadeSelecionadaId);
        if (cidadeSelecionada) {
            setCidade(cidadeSelecionada.Nome);
            setBairro(""); // Reseta o valor de bairro quando a cidade muda
            fetchBairros(cidadeSelecionada.id); // Fetch bairros based on selected cidade
        }
    };

    const fetchBairros = async (cidadeSelecionada) => {
        try {
            const response = await fetch(`https://ec2.col4bore.com.br/bairros?CidadeID=${cidadeSelecionada}`);
            // const response = await fetch(`http://localhost:3001/bairros?CidadeID=${cidadeSelecionada}`);
            const data = await response.json();
            setBairros(data);
        } catch (error) {
            console.error("Erro ao buscar bairros:", error);
        }
    };

    const fetchCidades = async () => {
        try {
            const response = await fetch(`https://ec2.col4bore.com.br/cidades`);
            // const response = await fetch(`http://localhost:3001/cidades`);
            const data = await response.json();
            setCidades(data);
        } catch (error) {
            console.error("Erro ao buscar cidades:", error);
        }
    };


    useEffect(() => {
        fetchCidades();
    }, []);

    const pesquisarDemandas = () => {
        const pesqAD = document.getElementById('pesqAD').value.toLowerCase();
        const statusAD = document.getElementById('statusAD').value;

        const demandasFiltradas = demandas.filter(demanda => {
            const matchTexto =
                (demanda.Titulo && demanda.Titulo.toLowerCase().includes(pesqAD)) ||
                (demanda.Descricao && demanda.Descricao.toLowerCase().includes(pesqAD)) ||
                (demanda.Cidade && demanda.Cidade.toLowerCase().includes(pesqAD));

            const matchStatus = statusAD === "todos" || demanda.Stats === statusAD;
            const matchCidade = !cidade || (demanda.Cidade && demanda.Cidade.toLowerCase() === String(cidade).toLowerCase());
            const matchBairro = !bairro || (demanda.Bairro && demanda.Bairro.toLowerCase() === bairro.toLowerCase());

            return matchTexto && matchStatus && matchCidade && matchBairro;
        });

        setDemandasFiltradas(demandasFiltradas);
    };

    useEffect(() => {
        const fetchDemandas = async () => {
            try {
                const response = await fetch('https://ec2.col4bore.com.br/demanda/demandaGeral');
                // const response = await fetch('http://localhost:3001/demanda/demandaGeral');
                if (response.ok) {
                    const data = await response.json();
                    setDemandas(data);
                    setDemandasFiltradas(data); // Atualiza demandasFiltradas inicialmente
                } else {
                    console.error('Erro ao buscar demandas');
                }
            } catch (error) {
                console.error('Erro ao buscar demandas:', error);
            }
        };

        if (logged === false) {
            openModal();
        } else {
            closeModal();
        }

        fetchDemandas();
    }, [logged]);

    return (
        <div className="TelaAD">
            <ModalBlock isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Usuário não logado" titulo={'Usuário não logado'} children={<UsrNaoLogado />} close={false} />
            {/* <ModalBlock isOpen={modalIsOpen2} onRequestClose={closeModal2} contentLabel="Serviço Indisponível no Momento" titulo={'Serviço Indisponível no Momento'} children={<ServIndisponivel />} close={false}/> */}
            <ScrollToTopOnMount />
            <Menu logged={logged} updateLogged={updateLogged} backendUrl={backendUrl} />
            <div className="contAD">
                <div className="inicioAD">
                    <div>
                        <form>
                            <h1>Acompanhe demandas</h1>
                            <input type="text" name="pesquisar" id="pesqAD" placeholder="Pesquisar" />
                            <select name="status" id="statusAD">
                                <option value="todos">Todos os status</option>
                                <option value="atendido">Atendido</option>
                                <option value="em_atendimento">Em atendimento</option>
                                <option value="recusado">Recusado</option>
                                <option value="nao_avaliado">Não avaliado</option>
                            </select>
                            <select name="cidade" id="cidadeAD" value={cidades.find(c => c.Nome === cidade)?.id || ""} onChange={handleCidadeChange}>
                                <option value="">Selecione a cidade</option>
                                {cidades.map(cidade => (
                                    <option key={cidade.id} value={cidade.id}>{cidade.Nome}</option>
                                ))}
                            </select>
                            {cidade && (
                                <select name="bairro" id="bairroAD" value={bairro} onChange={(e) => setBairro(e.target.value)}>
                                    <option value="">Selecione o bairro</option>
                                    {bairros.map(bairro => (
                                        <option key={bairro.ID} value={bairro.Nome}>{bairro.Nome}</option>
                                    ))}
                                </select>
                            )}
                            <button onClick={pesquisarDemandas} type="button">Pesquisar</button>
                        </form>
                    </div>
                    <div>
                        <img src={img1} alt="" />
                    </div>
                </div>
                <div className="histAD">
                    <TabelaDemandas demandas={demandasFiltradas} route={'ACdemandas'} />
                </div>
                <div className="parceirosAD">
                    <Parceiros items={items} />
                </div>
            </div>
            <Rodape />
        </div>
    );
}

export default AcompDemandas;
