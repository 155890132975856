import React, { useState } from "react";
import '../css/UsrNaoVerificado.css';
import { useNavigate } from 'react-router-dom';

function UsrNaoVerificado({email}) {
    const navigate = useNavigate();
    const [code, setCode] = useState(["", "", "", "", "", ""]);

    const handleChange = (e, index) => {
        const value = e.target.value.toUpperCase();
        if (/^[0-9A-F]$/.test(value) || value === "") {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value !== "" && index < 5) {
                document.getElementById(`code-input-${index + 1}`).focus();
            } else if (value === "" && index > 0) {
                document.getElementById(`code-input-${index - 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && code[index] === "") {
            if (index > 0) {
                document.getElementById(`code-input-${index - 1}`).focus();
            }
        }
    };

    const handleConfirmAccount = () => {
        const verificationCode = code.join("");
        const verifyObj = ({
            email: email,
            verificationCode: verificationCode
        }); // Junta as strings do array em uma única string
        fetch('https://ec2.col4bore.com.br/api/confirmation/verify-email', {
        // fetch('http://localhost:3001/api/confirmation/verify-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(verifyObj), // Passa o email e o código de verificação
        })
        .then(async response => {
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token);

                navigate(`/`);
            } else {
                throw new Error('Erro ao confirmar conta');
            }
        })
        .catch(error => {
            console.error('Erro ao confirmar conta:', error);
        });
    };

    return (
        <div className="telaUsrNaoVerificado">
            <div>
                <p>
                    Um código de verificação de 6 dígitos será enviado para
                    seu email, insira no espaço abaixo para fazer a confirmação 
                    e prosseguir com o login.
                </p>
                <p>
                    Atenção: O código será válido apenas por 10 minutos.
                </p>
            </div>
            <div className="code-inputs">
                {code.map((digit, index) => (
                    <input
                        key={index}
                        id={`code-input-${index}`}
                        type="text"
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        maxLength={1}
                        className="codVerifyInput"
                    />
                ))}
            </div>
            <div>
                <button className="confConta" onClick={handleConfirmAccount}>Confirmar conta</button>
            </div>
        </div>
    );
}

export default UsrNaoVerificado;
