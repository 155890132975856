import React from "react";
import '../css/UsrNaoLogado.css'
import { useNavigate } from 'react-router-dom';

function UsrNaoLogado() {

    const navigate = useNavigate();

    const GoToEntrar = () => {
        navigate('/entrar');
    };

    const GoToCadastro = () => {
        navigate('/cadastro')
    };

    return(
        <div className="telaUsrNaoLogado">
            <div>
                <button className="buttonIrLogin" onClick={GoToEntrar}>Entrar</button>
                <button className="buttonIrCadastro" onClick={GoToCadastro}>Cadastrar</button>
            </div>
        </div>
    );
};

export default UsrNaoLogado;