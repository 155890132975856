import React, { useState } from "react";
import Menu from "./Menu";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import Rodape from "./Rodape";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../css/RecSenha.css';
import img1 from '../../img/Home/image 01 3.png';
import ModalSenhaReseted from './ModalP'
import ModalSenhaResetedErro from './ModalP'
import SenhaAlt from './SenhaAlt'
import { useNavigate } from "react-router-dom";

function RecSenha({ logged, updateLogged }) {

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const openModal2 = () => setModalIsOpen2(true);
    const closeModal2 = () => setModalIsOpen2(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const GoToHome = () => {
        navigate('/');
    }

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setMessage('As senhas não coincidem');
            return;
        }

        if (!token || !email) {
            setMessage('Token ou email inválido');
            return;
        }

        try {
            const response = await fetch(`https://ec2.col4bore.com.br/api/recovery/reset-password`, {
            // const response = await fetch(`http://localhost:3001/api/recovery/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, token, newPassword: password }),
            });

            if (response.ok) {
                // setMessage('Senha atualizada com sucesso!');
                openModal();
            } else {
                const data = await response.json();
                setMessage(data.error || 'Erro ao atualizar a senha. Tente novamente.');
                openModal2();
            }
        } catch (error) {
            console.error('Erro ao atualizar a senha:', error);
            // setMessage('Erro ao atualizar a senha. Tente novamente.');
            openModal2();
        }
    };

    if (!token || !email) {
        GoToHome();
    }

    return (
        <div className="telaRecSenha">
        <ModalSenhaReseted isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Senha alterada com sucesso" titulo={'Senha alterada com sucesso'} children={<SenhaAlt />} close={false}/>
        <ModalSenhaResetedErro isOpen={modalIsOpen2} onRequestClose={closeModal2} contentLabel="Erro, tente novamente" titulo={'Erro, tente novamente'} close={true}/>
        <ScrollToTopOnMount />
        <Menu logged={logged} updateLogged={updateLogged}/>
        <div className="contentRecSenha">
            <div>
                <div>
                    <div>
                        <p>Insira sua nova senha no campo abaixo</p>
                    </div>
                    <div>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Senha"
                            name="senha"
                            id="senhaC"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <button
                            type="button"
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <div>
                        <input
                            type={showPassword2 ? 'text' : 'password'}
                            placeholder="Confirme sua senha"
                            name="senha2"
                            id="senha2C"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <button
                            type="button"
                            className="toggle-password"
                            onClick={togglePasswordVisibility2}
                        >
                            <FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <div>
                        <button className="bttSalEsqSenha" type="button" onClick={handleSubmit}>Salvar</button>
                    </div>
                    {message && <div>{message}</div>}
                </div>
                <div>
                    <img src={img1} alt="Imagem 1" />
                </div>
            </div>
        </div>
        <Rodape />
    </div> 
    );

};

export default RecSenha;
