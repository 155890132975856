import React, { useState, useEffect } from "react";
import '../css/DetalhesEvento.css'
import { jwtDecode } from 'jwt-decode';
import ModalExcEvento from './ModalP';
import ExcDemanda from './ExcDemanda';
import AltEvento from './AltEvento'
// import img2 from '../../img/RP/sendImage.png';

function DetalhesEvento( {evento, route} ) {

    const [token, setToken] = useState();
    const [tipoConta, setTipoConta] = useState();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const openModal2 = () => setModalIsOpen2(true);
    const closeModal2 = () => setModalIsOpen2(false);

    const [cidades, setCidades] = useState([])
    const [bairros, setBairros] = useState([])

    const [formData, setFormData] = useState({
        eventoID: evento.id,
        creatorID: evento.PessoaID,
        tituloVerEvento: evento.Titulo,
        cidadeVerEvento: evento.Cidade,
        bairroVerEvento: evento.Bairro,
        dataVerEvento: evento.data,
        descVerEvento: evento.Descricao,
        selectImgVerEvento: evento.Imagem,
        statsVerEvento: evento.stats,
    });

    // console.log(formData);

    const correctDate = () => {
        const data = formData.dataVerEvento.split('T');
        // const dataSplit = data[0].split('-');
        // const dataF = [dataSplit[2], dataSplit[1], dataSplit[0]].join('/');
        const dataF = data[0]

        return dataF;
    }

    const stats = [
        {type: 'nao_avaliado'},
        {type: 'em_analise'},
        {type: 'autorizado'},
        {type: 'recusado'},
    ];

    useEffect(() => {
        const fetchCidades = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/cidades`);
                //  const response = await fetch(`http://localhost:3001/cidades`);
                const data = await response.json();
                setCidades(data);
            } catch (error) {
                console.error("Erro ao buscar cidades:", error);
            }
        };
    
        fetchCidades();

        if (route === 'contaAdm') {
            const token = localStorage.getItem('token');
            const decodedToken = jwtDecode(token);
        
            const tipoConta = decodedToken.tipoConta;
            
            setToken(token);
            setTipoConta(tipoConta);
        }else {
            setTipoConta(1);
        }
        
    }, [route]);

    const fetchBairros = async (idCidade) => {
        try {
            const response = await fetch(`https://ec2.col4bore.com.br/bairros?CidadeID=${idCidade}`);
            // const response = await fetch(`http://localhost:3001/bairros?CidadeID=${idCidade}`);
            const data = await response.json();
            setBairros(data);
        } catch (error) {
            console.error("Erro ao buscar bairros:", error);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'bairroVerEvento') {

            // console.log(name);

            const nomeBairro = bairros[value-1].Nome
            
            setFormData({ ...formData, [name]: nomeBairro });
        } 
        
        else if (name === 'cidadeVerEvento') {

            const nomeCidade = cidades[value-1].Nome;

            fetchBairros(value);

            setFormData({ ...formData, [name]: nomeCidade });

        }
        
        else {

            setFormData({ ...formData, [name]: value });

        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            status: formData.statsVerEvento,
            userId: formData.creatorID
        };
    
        try {
            const response = await fetch('https://ec2.col4bore.com.br/evento/updateEvent', {
            // const response = await fetch('http://localhost:3001/evento/updateEvent', {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
    
            if (response.ok) {
                openModal2();
            } else {
                const errorData = await response.json();
                alert(errorData.error);
            }
        } catch (error) {
            console.error("Erro ao atualizar status do evento:", error);
            alert("Erro ao atualizar status do evento");
        }
    };

    // const changePhoto = (evt) => {
    //     const file = evt.target.files[0];
    //     setFormData({ ...formData, foto: file });

    //     const r = new FileReader();
    //     r.onload = () => {
    //         const imgEditEvento = document.getElementById('imgEditEvento');
            
    //         imgEditEvento.setAttribute('src', r.result);

    //     }
    //     r.readAsDataURL(file);
    // };

    const CheckStatus = (status) => {

        let resp

        if (status === 'autorizado') {
            resp = 'Autorizado';
        } else if (status === 'em_analise') {
            resp = 'Em análise';
        }
        else if (status === 'recusado') {
            resp = 'Recusado';
        }
        else if (status === 'nao_avaliado') {
            resp = 'Não avaliado';
        }

        return resp
    }

    const CheckStat = (stat, index) => {
        if (stat.type === formData.statsVerEvento) {
            return <option key={index} className="optionStatsVerEvento" value={stat.type} selected>{CheckStatus(stat.type)}</option>
        } else {
            return <option key={index} className="optionStatsVerEvento" value={stat.type}>{CheckStatus(stat.type)}</option>
        }
    }

    const GerTipoConta = (tipo, sec) => {
        if (sec === 1) {
            if (tipo === 1) {
                return <h2 className={formData.statsVerEvento} >{CheckStatus(formData.statsVerEvento)}</h2>;
            } else if (tipo === 2) {
                return (
                    <select className={formData.statsVerEvento} name="statsVerEvento" id="statsVerEvento" onChange={handleChange}>
                        {stats.map((stat, index) => (
                            CheckStat(stat, index)
                        ))}
                    </select>
                );
            }
        } else if (sec === 2) {
            if (tipo === 1) {
                return ''
            } else if (tipo === 2) {
                return (
                    <div className={route}>
                        <button>Salvar</button>
                        <button onClick={openModal} type="button">Excluir</button>
                    </div>
                )
            }
        }

        return null;
    };

    return (
        <div className="telaDetalhesEvento">
            <form onSubmit={handleSubmit}>
                <div>
                    <div>
                        <div>
                            {GerTipoConta(tipoConta, 1)}
                        </div>
                        {/* <select name="cidadeVerEvento" id="cidadeVerEvento" onChange={handleChange}>
                            <option value="" >{formData.cidadeVerEvento}</option>
                            {cidades.map((cidade, index) => (
                                <option key={cidade.id || index} value={cidade.id}>{cidade.Nome}</option>
                            ))}
                        </select> */}
                        <input value={formData.cidadeVerEvento} type="text" name="cidadeVerEvento" id="cidadeVerEvento"  onChange={handleChange} readOnly/>
                        <input value={formData.bairroVerEvento} type="text" name="bairroVerEvento" id="bairroVerEvento"  onChange={handleChange} readOnly/>
                        {/* <select name="bairroVerEvento" id="bairroVerEvento" onChange={handleChange}>
                            <option value="" >{formData.bairroVerEvento}</option>
                            {bairros.map((bairro, index) => (
                                <option key={bairro.ID || index} value={bairro.ID}>{bairro.Nome}</option>
                            ))}
                        </select> */}
                        <input type="text" name="tituloVerEvento" placeholder="Título" value={formData.tituloVerEvento} onChange={handleChange} readOnly/>
                        <input type="date" name="dataVerEvento" id="dataVerEvento" value={correctDate()} onChange={handleChange} readOnly/>
                        <textarea name="descVerEvento" value={formData.descVerEvento} id="descVerEvento" placeholder="Descreva o evento..." onChange={handleChange} readOnly></textarea>
                    </div>
                    <div>
                        {/* <input onChange={changePhoto} type="file" name="selectImgVerEvento" id="selectImgVerEvento" /> */}
                        <div>
                            <img src={convertBufferToImageURL(formData.selectImgVerEvento.data)} alt="Upload de Imagem" id="imgEditEvento" />
                        </div>
                    </div>
                </div>
                <div>
                    {GerTipoConta(tipoConta, 2)}
                </div>
            </form>
            <ModalExcEvento isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Excluir Evento?" titulo={'Excluir Evento?'} children={<ExcDemanda ID={formData.eventoID} closeModal={closeModal} route={'evento'}/>}/>
            <ModalExcEvento isOpen={modalIsOpen2} onRequestClose={closeModal2} contentLabel="Evento Alterado" titulo={'Evento Alterado'} children={<AltEvento />} close={false}/>
        </div>
    );
};

function convertBufferToImageURL(buffer) {
    const arrayBufferView = new Uint8Array(buffer);
    const blob = new Blob([arrayBufferView], { type: 'image/png' });
    return URL.createObjectURL(blob);
}

export default DetalhesEvento