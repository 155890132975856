import React from "react";
import '../css/AltEvento.css'

function AltEvento() {

    const reload = () => {
        window.location.reload()
    }

    return (
        <div className="telaAltEvento">
            <div>
                <p>O status do evento foi alterado com sucesso!</p>
            </div>
            <div>
                <button onClick={reload} type="button">Perfil</button>
            </div>
        </div>
    );
};

export default AltEvento