import React, { useState, useEffect } from "react";
import '../css/RelatosProblemas.css';
import Menu from "./Menu";
import Rodape from "./Rodape";
import ScrollToTopOnMount from './ScrollToTopOnMount';
import img1 from '../../img/RP/megafone 1.png';
import img2 from '../../img/RP/sendImage.png';
// import Galeria from "./Galeria";
import { jwtDecode } from "jwt-decode"; // Corrigido para importação correta
import ModalBlock from './ModalP';
import ModalRP from './ModalP';
import UsrNaoLogado from './UsrNaoLogado';
// import ModalInds from './ModalP';
// import ServIndisponivel from './ServIndisponivel';
import MsgDemandaCriada from './MsgDemandaCriada';

function RelatosProblemas({ itemsNov, logged, updateLogged, backendUrl }) {
    const [formData, setFormData] = useState({
        titulo: '',
        cidade: '',
        bairro: '',
        rua: '',
        numero: null,
        descricao: '',
        foto: null
    });

    const [cidades, setCidades] = useState([]);
    // const bairros = ['bairro 1', 'bairro 2'];

    // const [cidadeID, setCidadeID] = useState();

    const [bairros, setBairros] = useState([]);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    // const [modalIsOpen3, setModalIsOpen3] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const openModal2 = () => setModalIsOpen2(true);
    const closeModal2 = () => {
        setModalIsOpen2(false)
        window.location.reload();
    };

    // const openModal3 = () => setModalIsOpen3(true);
    // const closeModal3 = () => setModalIsOpen3(false);


    useEffect(() => {
        const fetchCidades = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/cidades`);
                //  const response = await fetch(`http://localhost:3001/cidades`);
                const data = await response.json();
                console.log(data);
                setCidades(data);
            } catch (error) {
                console.error("Erro ao buscar cidades:", error);
            }
        };

        if (logged === false) {
            openModal();
        } else {
            closeModal();
        }

        fetchCidades();

    }, [logged]);

    const changePhoto = (evt) => {
        const file = evt.target.files[0];
        setFormData({ ...formData, foto: file });

        const r = new FileReader();
        r.onload = () => {
            const imgRP = document.getElementById('imgRP');
            imgRP.setAttribute('src', r.result);
        }
        r.readAsDataURL(file);
    };

    const fetchBairros = async (idCidade) => {
        try {
            const response = await fetch(`https://ec2.col4bore.com.br/bairros?CidadeID=${idCidade}`);
            // const response = await fetch(`http://localhost:3001/bairros?CidadeID=${idCidade}`);
            // console.log('fetch aqui',idCidade);
            const data = await response.json();
            console.log(data);
            setBairros(data);
        } catch (error) {
            console.error("Erro ao buscar bairros:", error);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'bairro') {

            console.log(bairros[value].Nome);

            const nomeBairro = bairros[value].Nome

            setFormData({ ...formData, [name]: nomeBairro });
        }

        else if (name === 'cidade') {

            const nomeCidade = cidades[value - 1].Nome;

            fetchBairros(value);

            setFormData({ ...formData, [name]: nomeCidade });

        }

        else {

            setFormData({ ...formData, [name]: value });

        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        let pessoaID;
        try {
            const decodedToken = jwtDecode(token);
            pessoaID = decodedToken.userId;
        } catch (error) {
            console.error("Erro ao decodificar o token:", error);
            alert("Erro ao verificar o token de login.");
            return;
        }

        const form = new FormData();
        form.append('titulo', formData.titulo);
        form.append('cidade', formData.cidade);
        form.append('bairro', formData.bairro);
        form.append('rua', formData.rua);
        form.append('numero', formData.numero);
        form.append('descricao', formData.descricao);
        form.append('foto', formData.foto);
        form.append('pessoaID', pessoaID);

        try {
            const response = await fetch('https://ec2.col4bore.com.br/demanda/addDemanda', {
            // const response = await fetch('http://localhost:3001/demanda/addDemanda', {
                method: 'POST',
                body: form,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                openModal2();
            } else {
                const errorData = await response.json();
                alert(errorData.error);
            }
        } catch (error) {
            console.error("Erro ao enviar a demanda:", error);
            alert("Erro ao enviar a demanda");
        }
    };

    return (
        <div className="TelaRP">
            <ModalBlock isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Usuário não logado" titulo={'Usuário não logado'} children={<UsrNaoLogado />} close={false} />
            <ModalRP isOpen={modalIsOpen2} onRequestClose={closeModal2} contentLabel={'Relatos de Problemas'} titulo={'Relatos de Problemas'} children={<MsgDemandaCriada />} close={true} />
            {/* <ModalInds isOpen={modalIsOpen3} onRequestClose={closeModal3} contentLabel="Serviço Indisponível no Momento" titulo={'Serviço Indisponível no Momento'} children={<ServIndisponivel />} close={false}/> */}
            <ScrollToTopOnMount />
            <Menu logged={logged} updateLogged={updateLogged} backendUrl={backendUrl} />
            <div className="contRP">
                <div className="inicioRP">
                    <div>
                        <h1>Portal de Problemas</h1>
                    </div>
                    <div>
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <div>
                                        <select name="cidade" id="cidadeRP" onChange={handleChange}>
                                            <option value="">Escolha sua cidade</option>
                                            {cidades.map((cidade, index) => (
                                                <option key={cidade.id || index} value={cidade.id}>{cidade.Nome}</option>
                                            ))}
                                        </select>

                                        <select name="bairro" id="bairroRP" onChange={handleChange}>
                                            <option value="">Selecione o bairro relatado</option>
                                            {bairros.map((bairro, index) => (
                                                <option key={bairro.ID || index} value={index}>{bairro.Nome}</option>
                                            ))}
                                        </select>

                                        <input placeholder="Rua" type="text" name="rua" id="ruaRP" onChange={handleChange} />
                                        <input placeholder="Numero" type="text" name="numero" id="numeroRP" onChange={handleChange} />

                                        <input placeholder="Título" type="text" name="titulo" id="titRP" onChange={handleChange} />

                                        <textarea placeholder="Descreva o problema..." name="descricao" id="probRP" cols="30" rows="10" onChange={handleChange}></textarea>
                                    </div>
                                    <div>
                                        <input onChange={changePhoto} type="file" name="foto" id="fotoRP" />
                                        <div>
                                            <img src={img2} alt="Upload de Imagem" id="imgRP" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit">Relatar</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <img src={img1} alt="" />
                        </div>
                    </div>
                </div>
                <div className="novRP">
                    <div>
                        <h1>Ultimas novidades</h1>
                    </div>
                    <div>
                        {/* <Galeria itemsNov={itemsNov} button={'red'} /> */}
                    </div>
                </div>
            </div>
            <Rodape />
        </div>
    );
};

export default RelatosProblemas;
