import React from "react";
import '../css/MsgDemandaCriada.css'

function MsgDemandaCriada() {
    return (
        <div className="telaMsgDemandaCraida">
            <div>
                <p>Problema relatado com sucesso</p>
                <p>Acompanhe o status no seu 'Perfil' ou em 'Acompanhar Demandas'</p>
            </div>
        </div>
    );
};

export default MsgDemandaCriada;