import React, { useState } from "react";
import '../css/ListEventos.css'
import abrir from '../../img/Icons/Perfil/abrir.png';
import ModalVerEvento from './Modal'
import DetalhesEvento from './DetalhesEvento'

function ListEventos({eventos}) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedEvento, setSelectedEvento] = useState(null);

    const openModal = (evento) => {
        setSelectedEvento(evento);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedEvento(null);
    };

    const correctDate = (item) => {
        const data = item.split('T');
        const dataSplit = data[0].split('-');
        const dataF = [dataSplit[2], dataSplit[1], dataSplit[0]].join('/');
        // const dataF = data[0]

        return dataF;
    }

    return(
        <div className="telaListEventos">
            {eventos.map((evento, index) => (
                <div className={evento.stats} key={index}>
                    <p>{evento.Titulo}</p>
                    <p>{correctDate(evento.data)}</p>
                    <button onClick={() => openModal(evento)}><img src={abrir} alt="See" /></button>
                </div>
            ))}
            {selectedEvento &&
                <ModalVerEvento isOpen={modalIsOpen} onRequestClose={closeModal} 
                contentLabel="Visualizar Evento" titulo={'Visualizar Evento'} children={<DetalhesEvento evento={selectedEvento} route={'user'}/>}/>
            }
        </div>
    );
};

export default ListEventos;