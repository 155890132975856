import React, { useState } from "react";
import '../css/EsqSenha.css';

function EsqSenha({ email }) {
    const [formValues, setFormValues] = useState({
        emailEsq: email
    });

    const [message, setMessage] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch('https://ec2.col4bore.com.br/api/recovery/send-password-reset-email', {
            // const response = await fetch('http://localhost:3001/api/recovery/send-password-reset-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formValues.emailEsq }),
            });

            if (response.ok) {
                setMessage('Email de recuperação enviado com sucesso!');
            } else {
                setMessage('Erro ao enviar email de recuperação. Tente novamente.');
            }
        } catch (error) {
            console.error('Erro ao enviar email de recuperação:', error);
            setMessage('Erro ao enviar email de recuperação. Tente novamente.');
        }
    };

    return (
        <div className="telaEsqSenha">
            <div>
                <p>Informe o email cadastrado para o envio do link de autenticação para a troca de senha</p>
            </div>
            <div>
                <input
                    type="text"
                    name="emailEsq"
                    value={formValues.emailEsq}
                    onChange={handleInputChange}
                    placeholder="Email"
                />
            </div>
            <div>
                <button type="button" onClick={handleSubmit}>Enviar</button>
            </div>
            {message && <div>{message}</div>}
        </div>
    );
};

export default EsqSenha;
