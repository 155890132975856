import React, { useState, useEffect } from 'react';
import '../css/Entrar.css'
import Menu from './Menu';
import Rodape from './Rodape';
import img1 from '../../img/Home/image 01 3.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import ModalConfiEmail from './Modal';
import ModalEsqSenha from './ModalP';
import UsrNaoVerificado from './UsrNaoVerificado';
import EsqSenha from './EsqSenha'
import { jwtDecode } from 'jwt-decode';

function Entrar({updateLogged, logged, backendUrl}) {

    const navigate = useNavigate();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const openModal2 = () => setModalIsOpen2(true);
    const closeModal2 = () => setModalIsOpen2(false);

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: window.location.href,
            title: document.title,
          },
        });
      }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    const GoToCadastro = () => {

        navigate(`/cadastro`);

    };

    const fazerLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://ec2.col4bore.com.br/perfil/login', {
            // const response = await fetch('http://localhost:3001/perfil/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    senha: senha,
                }),
            });
            
            const acessoNegado = document.getElementById('acessoNegado');
    
            if (response.status === 401) {
                
                acessoNegado.style.display = 'block';

            } else if (response.ok) {

                window.dataLayer.push({
                    event: 'button_click',
                    button: {
                      id: 'entrar',
                      text: 'Entrar',
                    },
                });

                acessoNegado.style.display = 'none';
                const data = await response.json();
                const verified = data.verified;
                setEmail(data.emailLogado);
                
                if(verified){
                    localStorage.setItem('token', data.token);

                    const decodedToken = jwtDecode(data.token)

                    const pessoaID = decodedToken.userId
                    
                    try {
                        const response = await fetch(`https://ec2.col4bore.com.br/getimagem?pessoaID=${pessoaID}`);
                        // const response = await fetch(`http://localhost:3001/getimagem?pessoaID=${pessoaID}`);
                        if (response.ok) {
                            const data = await response.json();
                            if(data) {
                                const fotoData = data.foto.data;

                                // Converter array de números em Uint8Array
                                const uint8Array = new Uint8Array(fotoData);

                                // Criar um Blob a partir do Uint8Array
                                const blob = new Blob([uint8Array], { type: 'image/png' });

                                // Gerar um URL para o Blob
                                const fotoUrl = URL.createObjectURL(blob);

                                // Armazenar o URL no localStorage
                                localStorage.setItem('foto', fotoUrl);
                            }
                        } else {
                            console.error('Failed to fetch image:', response.statusText);
                        }
                    } catch (error) {
                        
                    }
                    
                    // Atualizar o estado para indicar que o usuário está logado
                    updateLogged(true);
        
                    // Redirecionar o usuário para a página inicial após o login
                    navigate(`/`);
                } else {
                    await fetch('https://ec2.col4bore.com.br/api/confirmation/send-confirmation-email', {
                    // await fetch('http://localhost:3001/api/confirmation/send-confirmation-email', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ email: data.emailLogado  })
                    });
    
                    console.log('não verificado');
                    openModal();
                }

            } else {
                console.error('Erro ao fazer login:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            // Aqui você pode tratar os erros, como exibir uma mensagem de erro para o usuário
        }
    };

    return (
        <div className='TelaEntrar'>
        <ModalConfiEmail isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Usuário não verificado" titulo={'Usuário não verificado'} children={<UsrNaoVerificado email={email}/>} close={true}/>
        <ModalEsqSenha isOpen={modalIsOpen2} onRequestClose={closeModal2} contentLabel="Esqueci minha senha" titulo={'Esqueci minha senha'} children={<EsqSenha email={email}/>} close={true}/>
            <ScrollToTopOnMount />
            <Menu logged={logged} updateLogged={updateLogged} backendUrl={backendUrl}/>
            <div className="contentLogin">
                <div>
                    <div>
                        <form className="login" onSubmit={fazerLogin}> {/* Adicione onSubmit e passe fazerLogin como manipulador de evento */}
                            <h1>Login</h1>

                            <input type="text" placeholder='Email ou CPF' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <div>
                                <input type={showPassword ? 'text' : 'password'} placeholder='Senha' value={senha} onChange={(e) => setSenha(e.target.value)} />
                                <button
                                    type="button"
                                    className="toggle-password"
                                    onClick={togglePasswordVisibility}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                            <div id='acessoNegado'>
                                <p>Usuário ou senha incorretos</p>
                            </div>
                            <button className='buttonEntLogin'  type="submit">Entrar</button>
                            <button className='buttonCadLogin' onClick={GoToCadastro} type="button">Cadastrar</button> {/* Altere onClick para type="submit" */}
                            <button className='esqSenha' onClick={openModal2} type="button">Esqueci minha senha</button>
                        </form>
                    </div>
                </div>
                <div>
                    <img src={img1} alt="" />
                </div>
            </div>
            <Rodape />
        </div>
    );
}

export default Entrar