import React from "react";
import '../css/EditPerfil.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function EditPerfil({ decodedToken, updateLogged }) {
    const navigate = useNavigate();
    const [inputSize, setInputSize] = useState(3);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };
    const togglePasswordVisibility3 = () => {
        setShowPassword3(!showPassword3);
    };

    const botEditPerfil = async (event) => {
        event.preventDefault();
        const campoObrig = document.getElementById('campoObrig');
        const senhasDiff = document.getElementById('senhasDiff');
        let errorCount = 0;
        const b1 = document.getElementById('b1');
        const b2 = document.getElementById('b2');
        const b3 = document.getElementById('b3');
        const resp = document.getElementById('respC');
        const respCont = document.getElementById('respCont');
        const trocaSenhaE = document.getElementById('trocaSenhaE');
        const filhosB1 = b1.querySelectorAll('*');
        const filhosB2 = b2.querySelectorAll('*');
        const filhosB3 = b3.querySelectorAll('input');
        const ids = [];
    
        filhosB1.forEach(filhos => {
            ids.push(filhos.id);
        });
        filhosB2.forEach(filhos => {
            ids.push(filhos.id);
        });
        filhosB3.forEach(filhos => {
            ids.push(filhos.id);
        });

        for (let i = 0; i < ids.length - inputSize; i++) {
            const element = document.getElementById(ids[i]);
            if (element.value === '') {
                element.style.border = '1px solid red';
                errorCount++;
            } else {
                element.style.border = 'none';
            }
        }
    
        if (!resp.checked) {
            respCont.style.color = 'red';
            errorCount++;
        } else {
            respCont.style.color = '#666666';
        }
    
        if (trocaSenhaE.checked) {
            const senha = document.getElementById(ids[12]).value;
            const confSenha = document.getElementById(ids[13]).value;
            setInputSize(1);
            if (senha !== confSenha) {
                senhasDiff.style.display = 'block';
                errorCount++;
            } else {
                senhasDiff.style.display = 'none';
            }
        } else {
            setInputSize(3);
        }
    
        if (errorCount === 0) {
            campoObrig.style.display = 'none';
            try {
                const token = localStorage.getItem('token'); // Supondo que o token JWT esteja armazenado no localStorage
                console.log(token);
                const response = await fetch('https://ec2.col4bore.com.br/perfil/updateAccount', {
                //  const response = await fetch('http://localhost:3001/perfil/updateAccount', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Adicione o token JWT ao header Authorization
                    },
                    body: JSON.stringify(formValues)
                });
    
                if (response.ok) {
                    // const data = await response.json();
                    updateLogged(false);
                    localStorage.removeItem('token');
                    navigate('/entrar');
                    // Trate a resposta de sucesso aqui, como redirecionar ou mostrar uma mensagem de sucesso
                } else if (response.status === 401) {
                    console.log('Senha Incorreta')
                } else {
                    const error = await response.json();
                    console.error('Erro ao atualizar informações da conta:', error);
                    // Trate a resposta de erro aqui, como mostrar uma mensagem de erro ao usuário
                }
            } catch (error) {
                console.error('Erro ao fazer a requisição:', error);
                // Trate qualquer erro que possa ocorrer durante a requisição
            }
        } else {
            campoObrig.style.display = 'block';
        }
    };

    const [formValues, setFormValues] = useState({
        nome: decodedToken.nome,
        email: decodedToken.email,
        cpf: decodedToken.cpf,
        cep: decodedToken.cep,
        senha: '',
        senhaNova: '',
        trocaDeSenha: false,
        endereco: decodedToken.endereco,
        num: decodedToken.numero,
        comp: decodedToken.complemento,
        cidade: decodedToken.cidade,
        bairro: decodedToken.bairro,
        estado: decodedToken.estado,
        tel: decodedToken.telefone
    });

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        let formattedValue = value;

        if (name === 'cpf') {
            formattedValue = formatCPF(value);
        }

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: formattedValue,
        }));

        if (name === 'cep' && value.length === 8) {
            const addressData = await fetchAddressData(value);
            if (addressData) {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    endereco: addressData.endereco,
                    pais: addressData.pais,
                    cidade: addressData.cidade,
                    bairro: addressData.bairro,
                    estado: addressData.estado
                }));
            }
        }        
    };

    useState(() => {
        if (decodedToken) {
            setFormValues((prevValues) => ({
                ...prevValues,
                ...decodedToken,
            }));
        }
    }, [decodedToken]);

    const fetchAddressData = async (cep) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();
            if (!data.erro) {
                const addressData = {
                    endereco: `${data.logradouro}`,
                    pais: 'Brasil',
                    cidade: data.localidade,
                    bairro: data.bairro,
                    estado: data.uf
                };
                return addressData;
            } else {
                console.error('CEP não encontrado');
                return null;
            }
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
            return null;
        }
    };  

    const formatCPF = (cpf) => {
        cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
        cpf = cpf.replace(/^(\d{3})(\d)/, '$1.$2'); // Coloca um ponto depois dos primeiros 3 dígitos
        cpf = cpf.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca um ponto depois dos próximos 3 dígitos
        cpf = cpf.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4'); // Coloca um hífen depois dos próximos 3 dígitos
        return cpf;
    };

    const trocaSenha = () => {
        const senhaNova = document.getElementById('inputSenha1');
        const trocaSenhaE = document.getElementById('trocaSenhaE');
        const senhaE = document.getElementById('senhaE');
        const senha2E = document.getElementById('inputSenha3');

        if (trocaSenhaE.checked) {
            formValues.trocaDeSenha = true;
            senhaNova.style.display = 'block';
            senhaNova.style.display = 'flex';
            senhaE.setAttribute('placeholder', 'Senha atual');
            senha2E.style.display = 'block';
            senha2E.style.display = 'flex';
        }
        else {
            formValues.trocaDeSenha = false;
            senhaNova.style.display = 'none';
            senhaE.setAttribute('placeholder', 'Senha');
            senha2E.style.display = 'none';
        }
    };


    return(
        <div className="telaEditPerfil">
            <div className="group">
                <div className="b1" id="b1">
                    <input type="text" placeholder='Nome' name="nome" id="nomeE" value={formValues.nome} onChange={handleInputChange}/>

                    <input type="text" placeholder='CPF' name="cpf" id="cpfE" value={formValues.cpf} onChange={handleInputChange} disabled/>

                    <input type="email" placeholder='Email' name="email" id="emailE" value={formValues.email} onChange={handleInputChange} disabled/>

                    <input type="tel" placeholder='Telefone' name="tel" id="telE" value={formValues.tel} onChange={handleInputChange}/>

                    <input type="text" placeholder='CEP' name="cep" id="cepE" value={formValues.cep} onChange={handleInputChange}/>
                </div>
                <div className="b2" id="b2">
                    <input type="text" placeholder='Endereço' name="endereco" id="endE" value={formValues.endereco} onChange={handleInputChange}/>

                    <input type="text" placeholder='Número' name="num" id="numE" value={formValues.num} onChange={handleInputChange}/>

                    <input type="text" placeholder='Complemento' name="comp" id="compE" value={formValues.comp} onChange={handleInputChange}/>

                    <input type="text" placeholder='Bairro' name="bairro" id="bairoE" value={formValues.bairro} onChange={handleInputChange}/>

                    <input type="text" placeholder='Cidade' name="cidade" id="cidadeE" value={formValues.cidade} onChange={handleInputChange}/>

                    <input type="text" placeholder='Estado' name="estado" id="estadoE" value={formValues.estado} onChange={handleInputChange}/>
                </div>
                <div className="b3" id="b3">

                    <div id="inputSenha2">
                        <input type={showPassword2 ? 'text' : 'password'} placeholder="Senha" name="senha" id="senhaE" value={formValues.senha} onChange={handleInputChange}/>
                        <button
                            type="button"
                            className="toggle-password"
                            onClick={togglePasswordVisibility2}
                        >
                            <FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} />
                        </button>
                    </div>

                    <div id="inputSenha1">
                        <input type={showPassword ? 'text' : 'password'} name="senhaNova" placeholder="Senha nova" id="senhaNova" value={formValues.senhaNova} onChange={handleInputChange}/>
                        <button
                            type="button"
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>

                    <div id="inputSenha3">
                        <input type={showPassword3 ? 'text' : 'password'} placeholder='Confirme sua senha' name="senha2" id="senha2E" />
                        <button
                            type="button"
                            className="toggle-password"
                            onClick={togglePasswordVisibility3}
                        >
                            <FontAwesomeIcon icon={showPassword3 ? faEyeSlash : faEye} />
                        </button>
                    </div>

                    <label>
                        <input type="checkbox" name="trocaSenha" id="trocaSenhaE" onChange={trocaSenha}/>
                        Deseja trocar a senha?
                    </label>
                </div>
            </div>

            <div className="group2">
                <div>
                    <input type="checkbox" name="resp" id="respC" className="resp" />
                    <label htmlFor="respC" id="respCont">
                        Declaro que as informações acima prestadas são verdadeiras, e assumo a inteira responsabilidade pelas mesmas.
                    </label>
                </div>
                <div>
                    <label id="campoObrig">
                        Este(s) campo(s) é(são) obrigatório(s).
                    </label>
                    <label id="senhasDiff">
                        As senhas devem ser iguais.
                    </label>
                </div>
                <div>
                    <button type="button" onClick={botEditPerfil}>Salvar</button>
                </div>
            </div>
        </div>
    );
};

export default EditPerfil