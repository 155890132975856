import React, { useState } from "react";
import { jwtDecode } from 'jwt-decode';
import ModalFotoAltSucesso from './ModalP';
import FotoAlt from './FotoAlt';
import icon from '../../img/Icons/Perfil/user.png';
import '../css/AltFotoPerfil.css';

function AltFotoPerfil() {

    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token)

    const fotoPerfil = localStorage.getItem('foto');

    const pessoaID = decodedToken.userId

    const [formData, setFormData] = useState({
        fotoPerfil: '',
        pessoaId: pessoaID,
    })

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const form = new FormData();
        form.append('foto', formData.fotoPerfil);
        form.append('pessoaID', formData.pessoaId);
    
        try {
            const response = await fetch('https://ec2.col4bore.com.br/perfil/AltFotoUser', {
            // const response = await fetch('http://localhost:3001/perfil/AltFotoUser', {
                method: 'PUT',
                body: form,
                headers: {
                    // 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
    
            if (response.ok) {
                openModal();
                // localStorage.removeItem('foto');
            } else {
                const errorData = await response.json();
                alert(errorData.error);
            }
        } catch (error) {
            console.error("Erro ao alterar a foto do usuário:", error);
            alert("Erro ao alterar a foto do usuário");
        }
    };
    

    const ConfFotoPerfil = () => {
        if (fotoPerfil === null) {
            return <img src={icon} alt="Foto Perfil" id="addFotoPerfil"/>
        } else {
            return <img src={fotoPerfil} alt="Foto Perfil" id="addFotoPerfil"/>
        }
    }

    const changePhoto = (evt) => {
        try {
            const file = evt.target.files[0];
            setFormData({ ...formData, fotoPerfil: file });
    
            const r = new FileReader();
            r.onload = () => {
                const addFotoPerfil = document.getElementById('addFotoPerfil');
                
                addFotoPerfil.setAttribute('src', r.result);
    
            }
            r.readAsDataURL(file);
        } catch (error) {
            
        }
    };

    return(
        <div className="telaAltFotoPerfil">
            <ModalFotoAltSucesso isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Foto alterada" titulo={'Foto alterada'} children={<FotoAlt />} close={false}/>
            <form onSubmit={handleSubmit}>
                <div>
                    <input onChange={changePhoto} type="file" name="AltFotoPerfil" id="AltFotoPerfil" />
                    <div>
                        {ConfFotoPerfil()}
                    </div>
                </div>
                <div>
                    <button className="buttonAltFotoPerfil">Salvar</button>
                </div>
            </form>
        </div>
    );
};

export default AltFotoPerfil;