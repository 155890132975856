import React from "react";
import '../css/FotoAlt.css'

function FotoAlt() {

    const reload = () => {
        window.location.reload()
    }
    return (
        <div className="telaFotoAlt">
            <div>
                <p>
                    Foto atualizada com sucesso!
                </p>
                <p>
                    Atenção: a alteração será aplicada no próximo login.
                </p>
            </div>
            <div>
                <button className="senhaAltButton" onClick={reload} type="button" >Perfil</button>
            </div>
        </div>
    );
};

export default FotoAlt;