import React, { useState, useEffect } from "react";
import '../css/AddEvento.css'
import img2 from '../../img/RP/sendImage.png';
import ModalEventoAdded from './ModalP';
import { jwtDecode } from 'jwt-decode';
import MsgEventoCriado from './MsgEventoCriado'

function AddEvento( ) {

    const [cidades, setCidades] = useState([])
    const [bairros, setBairros] = useState([])

    const [formData, setFormData] = useState({
        tituloAddEvento: '',
        cidadeAddEvento: '',
        bairroAddEvento: '',
        dataAddEvento: '',
        descAddEvento: '',
        selectImgAddEvento: null
    });

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    
    const closeModal = () => setModalIsOpen(false);

    useEffect(() => {
        const fetchCidades = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/cidades`);
                //  const response = await fetch(`http://localhost:3001/cidades`);
                const data = await response.json();
                setCidades(data);
            } catch (error) {
                console.error("Erro ao buscar cidades:", error);
            }
        };

        fetchCidades();
        
    });

    const fetchBairros = async (idCidade) => {
        try {
            const response = await fetch(`https://ec2.col4bore.com.br/bairros?CidadeID=${idCidade}`);
            // const response = await fetch(`http://localhost:3001/bairros?CidadeID=${idCidade}`);
            const data = await response.json();
            setBairros(data);
        } catch (error) {
            console.error("Erro ao buscar bairros:", error);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'bairroAddEvento') {

            
            const nomeBairro = bairros[value].Nome
            
            // console.log(bairros[value].Nome);

            setFormData({ ...formData, [name]: nomeBairro });
        } 
        
        else if (name === 'cidadeAddEvento') {

            const nomeCidade = cidades[value-1].Nome;

            fetchBairros(value);

            setFormData({ ...formData, [name]: nomeCidade });

        }
        
        else {
            setFormData({ ...formData, [name]: value });

        }
    };

    const changePhoto = (evt) => {
        const file = evt.target.files[0];
        setFormData({ ...formData, selectImgAddEvento: file });

        const r = new FileReader();
        r.onload = () => {
            const imgAddEvento = document.getElementById('imgAddEvento');
            
            imgAddEvento.setAttribute('src', r.result);

        }
        r.readAsDataURL(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');

        let pessoaID;
        try {
            const decodedToken = jwtDecode(token);
            pessoaID = decodedToken.userId;
        } catch (error) {
            console.error("Erro ao decodificar o token:", error);
            alert("Erro ao verificar o token de login.");
            return;
        }

        const form = new FormData();
        form.append('titulo', formData.tituloAddEvento);
        form.append('cidade', formData.cidadeAddEvento);
        form.append('bairro', formData.bairroAddEvento);
        form.append('data', formData.dataAddEvento);
        form.append('descricao', formData.descAddEvento);
        form.append('foto', formData.selectImgAddEvento);
        form.append('pessoaID', pessoaID);

        try {
            const response = await fetch('https://ec2.col4bore.com.br/evento/addEvento', {
            //  const response = await fetch('http://localhost:3001/evento/addEvento', {
                method: 'POST',
                body: form,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                openModal();
            } 
            // else {
            //     const errorData = await response.json();
            //     alert(errorData.error);
            // }
        } catch (error) {
            console.error("Erro ao enviar a evento:", error);
            alert("Erro ao enviar a evento");
        }
    };

    return (
        <div className="telaAddEvento">
            <ModalEventoAdded isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Evento criado" titulo={'Evento criado'} children={<MsgEventoCriado />} close={false}/>
            <form onSubmit={handleSubmit}>
                <div> 
                    <div>
                        <select name="cidadeAddEvento" id="cidadeAddEvento" onChange={handleChange}>
                            <option value="">Escolha sua cidade</option>
                            {cidades.map((cidade, index) => (
                                <option key={cidade.id || index} value={cidade.id}>{cidade.Nome}</option>
                            ))}
                        </select>
                        <select name="bairroAddEvento" id="bairroAddEvento" onChange={handleChange}>
                            <option value="">Selecione o bairro relatado</option>
                            {bairros.map((bairro, index) => (
                                <option key={bairro.ID || index} value={index}>{bairro.Nome}</option>
                            ))}
                        </select>
                        <input type="text" name="tituloAddEvento" placeholder="Título" onChange={handleChange}/>
                        <input type="date" name="dataAddEvento" id="dataAddEvento" onChange={handleChange}/>
                        <textarea name="descAddEvento" id="descAddEvento" placeholder="Descreva o evento..." onChange={handleChange}></textarea>
                    </div>
                    <div>
                        <input onChange={changePhoto} type="file" name="selectImgAddEvento" id="selectImgAddEvento"/>
                        <div>
                            <img src={img2} alt="Upload de Imagem" id="imgAddEvento" />
                        </div>
                    </div>
                </div>
                <div>
                    <button>Criar</button>
                </div>
            </form>
        </div>
    );
};

export default AddEvento