import React, { useState, useEffect} from "react";
import Menu from "./Menu";
import Rodape from "./Rodape";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import '../css/BairroInteligente.css';
import Galeria from './Galeria.jsx';
import Calendario from './Calendario.jsx'

function BairroInteligente( {BIitem, itemsNov, logged, updateLogged, backendUrl} ) {

    const [eventos, setEventos] = useState();

    useEffect(() => {

        const fetchEventos = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/evento/eventos`)
                // const response = await fetch(`http://localhost:3001/evento/eventos`)
                if (response.ok) {
                    const data = await response.json();
                    setEventos(data);
                }
            } catch (error) {
                console.error('Erro ao buscar eventos:', error);
            }
        }

        fetchEventos()

    });

    
    return (
        <div className="TelaBI">
            <ScrollToTopOnMount />
            <Menu logged={logged} updateLogged={updateLogged} backendUrl={backendUrl}/>
            <div className="contBI">
                <div className="inicioBI">
                   <div>
                        <Calendario />
                    </div>
                </div>
                <div className="novBI">
                    <div>
                        <h1>Ultimas novidades</h1>
                    </div>
                    <div>
                        <Galeria itemsNov={eventos} button={'blue'}/>
                    </div>
                </div>
            </div>
            <Rodape />
        </div>
    );
};

export default BairroInteligente;