import React from "react";
import '../css/SenhaAlt.css'
import { useNavigate } from "react-router-dom";

function SenhaAlt() {
    const navigate = useNavigate();

    const GoToEntrar = () => {
        navigate('/entrar');
    } 

    return (
        <div className="telaSenhaAlt">
            <div>
                <p>
                    Senha atualizada com sucesso!
                </p>
            </div>
            <div>
                <button className="senhaAltButton" onClick={GoToEntrar} type="button" >Entrar</button>
            </div>
        </div>
    );
};

export default SenhaAlt;