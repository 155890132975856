import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Home from './Components/jsx/Home.jsx';
import Entrar from './Components/jsx/Entrar.jsx';
import Cadastro from './Components/jsx/Cadastro.jsx';
import Perfil from './Components/jsx/Perfil.jsx';
import AS from './Components/jsx/AgendarServ.jsx';
import logo from './img/Logo/Col4bore logo 3.png';
import AD from './Components/jsx/AcompDemandas.jsx';
import BI from './Components/jsx/BairroInteligente.jsx';
import RP from './Components/jsx/RelatosProblemas.jsx';
import RecSenha from './Components/jsx/RecSenha.jsx'

function App() {
  const [logged, setLogged] = useState(false);

  // Acessar a variável de ambiente
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const updateLogged = async (isLogged) => {
    setLogged(isLogged);
  };

  const items = [
    { id: 1, content: logo },
    { id: 2, content: logo },
    { id: 3, content: logo },
    { id: 4, content: logo },
    { id: 5, content: logo },
    { id: 6, content: logo },
    { id: 7, content: logo },
    { id: 8, content: logo },
  ];

  const itemsNov = [
    { id: 1, content: logo, txt: 'tit1' },
    { id: 2, content: logo, txt: 'tit2' },
    { id: 3, content: logo, txt: 'tit3' },
    { id: 4, content: logo, txt: 'tit4' },
    { id: 5, content: logo, txt: 'tit5' },
    { id: 6, content: logo, txt: 'tit6' },
    { id: 7, content: logo, txt: 'tit7' },
    { id: 8, content: logo, txt: 'tit8' },
    { id: 9, content: logo, txt: 'tit9' },
    { id: 10, content: logo, txt: 'tit10' },
    { id: 11, content: logo, txt: 'tit11' },
    { id: 12, content: logo, txt: 'tit12' },
    { id: 13, content: logo, txt: 'tit13' },
    { id: 14, content: logo, txt: 'tit14' },
    { id: 15, content: logo, txt: 'tit15' },
    { id: 16, content: logo, txt: 'tit16' },
  ];

  const demandas = [
    { id: 1, nome: 'Demanda 1', status: 'atendido' },
    { id: 2, nome: 'Demanda 2', status: 'em_atendimento' },
    { id: 3, nome: 'Demanda 3', status: 'recusado' },
    { id: 4, nome: 'Demanda 4', status: 'nao_avaliado' },
    { id: 5, nome: 'Demanda 5', status: 'recusado' },
    { id: 6, nome: 'Demanda 6', status: 'em_atendimento' },
    { id: 7, nome: 'Demanda 7', status: 'atendido' },
    { id: 8, nome: 'Demanda 8', status: 'nao_avaliado' },
    { id: 9, nome: 'Demanda 9', status: 'atendido' },
    { id: 10, nome: 'Demanda 10', status: 'em_atendimento' },
    { id: 11, nome: 'Demanda 11', status: 'recusado' },
    { id: 12, nome: 'Demanda 12', status: 'nao_avaliado' },
    { id: 13, nome: 'Demanda 13', status: 'recusado' },
    { id: 14, nome: 'Demanda 14', status: 'em_atendimento' },
    { id: 15, nome: 'Demanda 15', status: 'atendido' },
    { id: 16, nome: 'Demanda 16', status: 'nao_avaliado' },
  ];

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home items={items} logged={logged} updateLogged={updateLogged} backendUrl={backendUrl} />} />
        <Route path="/entrar" element={<Entrar updateLogged={updateLogged} logged={logged} backendUrl={backendUrl} />} />
        <Route path="/cadastro" element={<Cadastro backendUrl={backendUrl} />} />
        <Route path="/perfil" element={<Perfil updateLogged={updateLogged} logged={logged} backendUrl={backendUrl} />} />
        <Route path='/agendar-servicos' element={<AS items={items} updateLogged={updateLogged} logged={logged} backendUrl={backendUrl} />} />
        <Route path='/acompanhar-demandas' element={<AD items={items} demandas={demandas} updateLogged={updateLogged} logged={logged} backendUrl={backendUrl} />} />
        <Route path='/bairro-inteligente' element={<BI BIitem={items} itemsNov={itemsNov} updateLogged={updateLogged} logged={logged} backendUrl={backendUrl} />} />
        <Route path='relatos-de-problemas' element={<RP itemsNov={itemsNov} updateLogged={updateLogged} logged={logged} backendUrl={backendUrl} />} />
        <Route path='/recuperar-senha' element={<RecSenha updateLogged={updateLogged} logged={logged} backendUrl={backendUrl} />} />
      </Routes>
    </Router>
  );
}

export default App;
