import React from "react";
import '../css/CadRealizado.css';
import { useNavigate } from "react-router-dom";

function CadRealizado() {

    const navigate = useNavigate();

    const GoToEntrar = () => {
        navigate('/entrar');
    }

    return (
        <div className="telaCadRealizado">
            <div>
                <p>
                    Cadastro realizado com sucesso!
                </p>
                <p>
                    No primeiro login será enviado um código de confirmação para 
                    o email cadastrado, afim de validar sua conta.
                </p>
            </div>
            <div>
                <button onClick={GoToEntrar}>
                    Entrar
                </button>
            </div>
        </div>
    );
};

export default CadRealizado;