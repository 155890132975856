import React from "react";
import '../css/ExcDemanda.css';

function ExcDemanda({ ID, closeModal, route }) {

    const handleDelete = async () => {

        if (route === 'evento') {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/evento/removeEvento/${ID}`, {
                // const response = await fetch(`http://localhost:3001/evento/removeEvento/${ID}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                if (response.ok) {
                    closeModal();
                    window.location.reload(); // Recarrega a página
                } else {
                    const errorData = await response.json();
                    alert(`Erro ao remover evento: ${errorData.error}`);
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                alert("Erro ao remover evento: Erro interno do servidor");
            }
        }else {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/demanda/removeDemanda/${ID}`, {
                // const response = await fetch(`http://localhost:3001/demanda/removeDemanda/${ID}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                if (response.ok) {
                    closeModal();
                    window.location.reload(); // Recarrega a página
                } else {
                    const errorData = await response.json();
                    alert(`Erro ao remover evento: ${errorData.error}`);
                }
            } catch (error) {
                console.error("Erro ao fazer a requisição:", error);
                alert("Erro ao remover evento: Erro interno do servidor");
            }
        }
    };

    return (
        <div className="telaExcDemanda">
            <button onClick={handleDelete}>Excluir</button>
        </div>
    );
};

export default ExcDemanda;
