import React from "react";
import '../css/Perfil.css'
import Menu from './Menu';
import Rodape from './Rodape';
import ModalEditPerfil from './Modal'
import ModalAddEvento from './Modal'
import ModalChangeFoto from './ModalP'
import AltFotoPerfil from './AltFotoPerfil'
import AddEvento from './AddEvento'
import EditPerfil from "./EditarPeril";
import icon from '../../img/Icons/Perfil/user.png';
import edit from '../../img/Icons/Perfil/edit.png';
// import emp from '../../img/Icons/Perfil/emp.png';
import loc from '../../img/Icons/Perfil/loc.png';
import hands from '../../img/Icons/Perfil/hands.png';
import addEvento from '../../img/Icons/Perfil/addEvento.png';
import ScrollToTopOnMount from './ScrollToTopOnMount';
import TabelaDemandas from "./TabelaDemandas";
import TabelaEventos from "./TabelaEventos";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useState, useEffect } from "react";

function Perfil( {updateLogged, logged, backendUrl} ) {

    const [eventos, setEventos] = useState([]);

    const token = localStorage.getItem('token');
    const fotoPerfil = localStorage.getItem('foto');

    // console.log(token);

    const decodedToken = jwtDecode(token)

    const tipoConta = decodedToken.tipoConta;
    const pessoaID = decodedToken.userId;
    const nome = decodedToken.nome;
    const email = decodedToken.email;
    const telefone = decodedToken.telefone;
    const cidade = decodedToken.cidade;
    const estado = decodedToken.estado;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [modalIsOpen3, setModalIsOpen3] = useState(false);
    const [demandas, setDemandas] = useState([]);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const openModal2 = () => setModalIsOpen2(true);
    const closeModal2 = () => setModalIsOpen2(false);

    const openModal3 = () => setModalIsOpen3(true);
    const closeModal3 = () => setModalIsOpen3(false);

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const response = await fetch(`https://ec2.col4bore.com.br/perfil/logout`, {
            // const response = await fetch(`http://localhost:3001/perfil/logout`, {
                method: 'POST',
                credentials: 'include' // Inclua as credenciais na solicitação
            });
            // const data = await response.json();
            updateLogged(false);
            localStorage.removeItem('token');
            localStorage.removeItem('foto');
            navigate('/'); // Redirecione o usuário para a página inicial após o logout
        } catch (error) {
            console.error('Erro ao fazer logout:', error);
            // Trate o erro, por exemplo, exibindo uma mensagem de erro para o usuário
        }
    };

    const splitNome = (nome) => {
        const nomeSplited = nome.split(' ');
        // console.log(nomeSplited);
        const nomePerfil = nomeSplited[0] + ' ' + nomeSplited[nomeSplited.length - 1];

        return nomePerfil;
    }

    useEffect(() => {
        const fetchDemandas = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/demanda/demandaPerfil?pessoaID=${pessoaID}`, {
                // const response = await fetch(`http://localhost:3001/demanda/demandaPerfil?pessoaID=${pessoaID}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setDemandas(data);
                console.log(data);
            } catch (error) {
                console.error('Erro ao buscar demandas:', error);
            }
        };

        const fetchEventos = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/evento/eventos`, {
                // const response = await fetch(`http://localhost:3001/evento/eventos`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setEventos(data);
                // console.log(data);
            } catch (error) {
                console.error('Erro ao buscar eventos:', error);
            }
        };


        if (tipoConta === 2) {
            fetchEventos();
        }
    
        fetchDemandas();
    }, [pessoaID, token, tipoConta]);

    const confFoto = () => {
        if (fotoPerfil) {
            return <img src={fotoPerfil} alt="Foto Perfil" />
        } else {
            return <img src={icon} alt="" />
        }
    }

    const GerTipoConta = (tipo, sec) => {
        if (sec === 1) {
            if (tipo === 1) {
                return 'Usuário';
            } else if (tipo === 2) {
                return 'Administrativo';
            }
        } else if (sec === 2) {
            if (tipo === 1) {
                return <button><img src={hands} alt="hands" />Torne-se um parceiro</button>;
            } else if (tipo === 2) {
                return <button className="gerenDemandas"><img src={hands} alt="hands" />Gerenciar demandas</button>;
            }
        } else if (sec === 3) {
            if (tipo === 1) {
                return null;
            } else if (tipo === 2) {
                return (
                    <div className="eventosPerfil">
                        <div>
                            <h1>Eventos</h1>
                            <div>
                                <button onClick={openModal2} type="button"><img src={addEvento} alt="addEvento" />Adicionar</button>
                            </div>
                        </div>
                        <div>
                            <TabelaEventos eventos={eventos} route="perfil" />
                        </div>
                    </div>
                );
            }
        }
    
        return null;
    };

    return (
        <div className="telaPerfil">
            <ModalChangeFoto isOpen={modalIsOpen3} onRequestClose={closeModal3} contentLabel="Alterar foto" titulo={'Alterar foto'} children={<AltFotoPerfil />}/>
            <ScrollToTopOnMount />
            <Menu logged={logged} updateLogged={updateLogged} backendUrl={backendUrl}/>
                <div className="contentPerfil">
                    <div className="geral">
                        <div>
                            <div>
                                {confFoto()}
                                {/* <img src={icon} alt="" /> */}
                                <button onClick={openModal3} type="button">+</button>
                            </div>
                            <h1>{splitNome(nome)}</h1>
                            <hr />
                            <h3>{GerTipoConta(tipoConta, 1)}</h3>
                            {/* <div>

                            </div> */}
                        </div>
                        <div>
                            <div>
                                <div>
                                    <h1><img src={loc} alt="loc" />Localização</h1>
                                    <p>{cidade} - {estado}</p>
                                    <hr />
                                    <p>{email}</p>
                                    <hr />
                                    <p>{telefone}</p>
                                </div>
                                <div>
                                    {GerTipoConta(tipoConta, 2)}
                                </div>
                            </div>
                            <div>
                                <div>
                                    <button onClick={openModal}><img src={edit} alt="edit" />Editar meu perfil</button>
                                    {/* <button><img src={emp} alt="cad" />Cadastre sua empresa</button> */}
                                </div>
                                <div>
                                    <button onClick={handleLogout}>Sair</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalEditPerfil isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Editar Perfil" titulo={'Editar Perfil'} children={<EditPerfil decodedToken={decodedToken} updateLogged={updateLogged} />}></ModalEditPerfil>
                    <ModalAddEvento isOpen={modalIsOpen2} onRequestClose={closeModal2} contentLabel="Adicionar Evento" titulo={'Adicionar Evento'} children={<AddEvento />}/>
                    <div className="hist">
                        <div>
                            <h1>Histórico</h1>
                        </div>
                        <div>
                            <TabelaDemandas demandas={demandas} route={'perfil'}/>
                        </div>
                    </div>
                    {GerTipoConta(tipoConta, 3)}
                </div>
            <Rodape />
        </div>
    );
};


export default Perfil;