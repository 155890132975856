import React from 'react';
import Modal from 'react-modal';
import '../css/ModalP.css'; // Certifique-se de que este arquivo CSS existe

Modal.setAppElement('#root');

const ModalP = ({ isOpen, onRequestClose, contentLabel, titulo, children, close }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className="modal-contentP"
      overlayClassName={close === false ? 'modal-overlayP false' : 'modal-overlayP'}
      shouldCloseOnEsc={close}
      shouldCloseOnOverlayClick={close}
    >
      <h1 className='titModalP'>{titulo}</h1>
      <button
        id='closeModalP'
        onClick={onRequestClose}
        className={close === false ? "modal-close-button modal-close-button-false" : "modal-close-button"}
      >
        ×
      </button>
      <div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalP;