import React, { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import '../css/Menu.css';
import logo from '../../img/Logo/Col4bore logo 3.png';
// import plus from '../../img/Icons/Menu/Plus.png';
import perfil from '../../img/Icons/Menu/Perfil.png';
import { useNavigate } from 'react-router-dom';

function Menu({ logged, updateLogged }) {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [pessoaNome, setPessoaNome] = useState();
    
    const fotoPerfil = localStorage.getItem('foto');

    useEffect(() => {
        const checkToken = async () => {
            const token = localStorage.getItem('token');
            if (token) {

                const decodedToken = jwtDecode(token);
                const nomeSplited = decodedToken.nome.split(' ')
                setPessoaNome(nomeSplited[0])
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp > currentTime) {
                    updateLogged(true);
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('foto')
                    updateLogged(false);
                }
            }
        };

        const updateLoggedIn = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/me`);
                // const response = await fetch(`http://localhost:3001/me`);
                if (response.ok) {
                    const data = await response.json();
                    if (data.loggedIn === true) {
                        updateLogged(true);
                    }
                }
            } catch (error) {
                console.error('Erro ao verificar autenticação:', error);
            }
        };

        checkToken();
        updateLoggedIn();
    }, [updateLogged]);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };
    const GoToHome = () => {
        navigate('/');
    };
    const GoToEntrarOuPerfil = () => {
        if (logged === true) {
            navigate('/perfil');
        } else {
            navigate('/entrar');
        }
    };

    const GoToAS = () => {
        navigate('/agendar-servicos');
    }

    const GoToAD = () => {
        navigate('/acompanhar-demandas');
    }
    const GoToBI = () => {
        navigate('/bairro-inteligente');
    }

    const GoToRP = () => {
        navigate('/relatos-de-problemas');
    }

    return (
        <div className={`menu ${scrollPosition > 0 ? 'scroll' : ''}`}>
            <div className="menu-toggle" onClick={toggleMenu}>
                <button className="hamburger">&#9776;</button>
            </div>
            <div className="logo">
                <div>
                    <button onClick={GoToHome} type="button">
                        <img src={logo} alt="Logo" />
                        <h1>Col4bore</h1>
                    </button>
                </div>
            </div>
            <div className={`buttons ${showMenu ? 'show' : ''}`}>
                <button onClick={GoToAS} type="button"><span>Agendar Serviços</span></button>
                <button onClick={GoToAD} type="button"><span>Acompanhar Demandas</span></button>
                <button onClick={GoToBI} type="button"><span>Bairro Inteligente</span></button>
                <button onClick={GoToRP} type="button"><span>Relatos de Problemas</span></button>
                {/* <button type="button"><img src={plus} alt="Plus" /></button> */}
                <button onClick={GoToEntrarOuPerfil} type="button"><img className={fotoPerfil === null ? "" : "fotoPerfilMenu"} src={fotoPerfil === null ? perfil : fotoPerfil} alt="Perfil" /> {logged ? pessoaNome : 'Entrar'}</button>
            </div>
        </div>
    );
}

export default Menu;