import React, { useState } from "react";
// import img1 from '../../img/RP/sendImage.png';
import '../css/DetalhesDemanda.css';
import ModalExcDemanda from './ModalP';
import ExcDemanda from './ExcDemanda'
import { useEffect } from "react";

function DetalhesDemanda( {demanda, route} ) {

    const dataHora = demanda.timestamp.split('T')
    const data = dataHora[0].split('-')
    const dataF = [data[2], data[1], data[0]].join('/')

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    
    const closeModal = () => setModalIsOpen(false);

    const CheckStatus = (status) => {

        let resp

        if (status === 'atendido') {
            resp = 'Atendido';
        }else if (status === 'em_atendimento') {
            resp = 'Em atendimento';
        }
        else if (status === 'recusado') {
            resp = 'Recusado';
        }
        else if (status === 'nao_avaliado') {
            resp = 'Não avaliado';
        }

        return resp
    }

    useEffect(() => {
        if (route === 'ACdemandas') {
            document.getElementById("excDemanda").style.display = 'none';
        }

    }, [route]);

    return (
        <div className="telaDetDemanda">
            <div>
                <div>
                    <div>
                        <div>
                            <h3 className={demanda.Stats}>{CheckStatus(demanda.Stats)}</h3>
                            <input type="text" name="dataHora" id="dataHoraD" value={'Criado em: '+dataF} readOnly/>
                        </div>
                        <input type="text" name="cidadeD" id="cidadeD" value={demanda.Cidade}  readOnly/>
                        <input type="text" name="bairroD" id="bairroD" value={demanda.Bairro} readOnly/>
                        <input type="text" name="tituloD" id="tituloD" value={demanda.Titulo} readOnly/>
                        <textarea name="descricaoD" id="descricaoD" value={demanda.Descricao} readOnly></textarea>
                    </div>
                    <div>
                        <div>
                            <img src={convertBufferToImageURL(demanda.Imagem.data)} alt={'Imagem'} />
                        </div>
                    </div>
                </div>
                <div>
                    <button id="excDemanda" onClick={openModal}>Excluir</button>
                </div>
                <ModalExcDemanda isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Excluir Demanda?" titulo={'Excluir Demanda?'} children={<ExcDemanda ID={demanda.DemandaID} closeModal={closeModal}/>}/>
            </div>
        </div>
    );
};

function convertBufferToImageURL(buffer) {
    const arrayBufferView = new Uint8Array(buffer);
    const blob = new Blob([arrayBufferView], { type: 'image/png' });
    return URL.createObjectURL(blob);
}

export default DetalhesDemanda;