import React from 'react';
import Modal from 'react-modal';
import '../css/Modal.css'; // Certifique-se de que este arquivo CSS existe

Modal.setAppElement('#root');

const ModalGeral = ({ isOpen, onRequestClose, contentLabel, titulo, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <button
        onClick={onRequestClose}
        className="modal-close-button"
      >
        ×
      </button>
      <div>
        <h1 className='titModal'>{titulo}</h1>
        {children}
      </div>
    </Modal>
  );
};

export default ModalGeral;