import React, { useState, useEffect } from 'react';
import '../css/Galeria.css';
import ModalVerEvento from './Modal'
import DetalhesEvento from './DetalhesEvento'

function Galeria({ itemsNov, button }) {
  const [displayedImages, setDisplayedImages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const imagesPerPage = 4;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (evento) => {
    setSelectedEvento(evento);
    setModalIsOpen(true);
  }
  const closeModal = () => {
    setSelectedEvento(null)
    setModalIsOpen(false);
  }

  const [selectedEvento, setSelectedEvento] = useState(null);

  useEffect(() => {
    if (itemsNov && itemsNov.length > 0) {
      const images = itemsNov.map(item => convertBufferToImageURL(item.Imagem.data));
      setDisplayedImages(splitIntoRows(images.slice(0, imagesPerPage)));
    }
  }, [itemsNov]);

  const loadMoreImages = () => {
    const newIndex = startIndex + 1;
    if (itemsNov && itemsNov.length > 0) {
      const newImages = itemsNov.slice(newIndex * imagesPerPage, (newIndex + 1) * imagesPerPage).map(item => convertBufferToImageURL(item.Imagem.data));
      const newRows = splitIntoRows(newImages);
      setDisplayedImages([...displayedImages, ...newRows]);
      setStartIndex(newIndex);
    }
  };

  const splitIntoRows = (images) => {
    const rows = [];
    for (let i = 0; i < images.length; i += 4) {
      rows.push(images.slice(i, i + 4));
    }
    return rows;
  };

  useEffect(() => {
    const buttonMais = document.getElementById('buttonMais');
    if (buttonMais && button) {
      if (button === 'blue') {
        buttonMais.style.backgroundColor = '#3C59E1';
      } else {
        buttonMais.style.backgroundColor = '#CD1616';
      }
    }
  }, [button]);

  if (!itemsNov || itemsNov.length === 0) {
    return null; // Renderizar algo caso itemsNov seja indefinido ou vazio
  }

  const handleClick = (evento) => {
    if (evento) {
      openModal(evento)
    }
};

  return (
    <div className='TelaGaleria'>
      {displayedImages.map((row, rowIndex) => (
        <div key={rowIndex} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          {row.map((image, index) => (
            <div onClick={() => {handleClick(itemsNov[startIndex + rowIndex * 4 + index])}} key={index} style={{ width: 'calc(25% - 20px)', maxWidth: '200px' }}>
              <img src={image} alt={`Imagem ${startIndex + rowIndex * 4 + index + 1}`} style={{ width: '100%' }} />
              <p>{itemsNov[startIndex + rowIndex * 4 + index]?.Titulo}</p>
            </div>
          ))}
          {rowIndex === displayedImages.length - 1 && row.length < 4 && (
            <div style={{ flex: `0 0 calc(${(4 - row.length) * 25}% - ${(4 - row.length) * 10}px)` }} />
          )}
        </div>
      ))}
      {itemsNov.length > displayedImages.length * imagesPerPage && (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <button id='buttonMais' onClick={loadMoreImages}>Mais</button>
        </div>
      )}
      {selectedEvento &&
          <ModalVerEvento isOpen={modalIsOpen} onRequestClose={closeModal} 
          contentLabel="Visualizar Evento" titulo={'Visualizar Evento'} children={<DetalhesEvento evento={selectedEvento} route={'user'}/>}/>
      }
    </div>
  );
}

function convertBufferToImageURL(buffer) {
  const arrayBufferView = new Uint8Array(buffer);
  const blob = new Blob([arrayBufferView], { type: 'image/png' });
  return URL.createObjectURL(blob);
}

export default Galeria;
