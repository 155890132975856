// Calendario.js

import React, { useEffect, useState } from 'react';
import '../css/Calendario.css';
import Dia from './Dia';

const Calendario = () => {
    const [eventos, setEventos] = useState([]);
    const [mes, setMes] = useState(new Date().getMonth());
    const [ano, setAno] = useState(new Date().getFullYear());

    useEffect(() => {
        const fetchEventos = async () => {
            try {
                const response = await fetch(`https://ec2.col4bore.com.br/evento/eventos`)
                // const response = await fetch(`http://localhost:3001/evento/eventos`)
                if (response.ok) {
                    const data = await response.json();
                    setEventos(data);
                }
            } catch (error) {
                console.error('Erro ao buscar eventos:', error);
            }
        };

        fetchEventos();
    }, []);

    const correctDate = (evento) => {
        const [dataBD,] = evento.data.split('T');
        const [anoBD, mesBD, diaBD] = dataBD.split('-');
        return {
            dia: parseInt(diaBD),
            mes: parseInt(mesBD) - 1,
            ano: parseInt(anoBD)
        };
    };

    const criarDias = () => {
        const dias = [];
        const diasNoMes = new Date(ano, mes + 1, 0).getDate();
        const primeiroDia = new Date(ano, mes, 1).getDay();

        // Preencher os dias do calendário
        let dia = 1;
        for (let i = 0; i < 6; i++) {
            const semana = [];
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < primeiroDia) {
                    semana.push(<Dia key={`empty-${i}-${j}`} />);
                } else if (dia <= diasNoMes) {
                    const currentDay = dia;
                    const eventosDoDia = eventos.filter(evento => {
                        const { dia, mes: eventoMes, ano: eventoAno } = correctDate(evento);
                        return dia === currentDay && eventoMes === mes && eventoAno === ano;
                    });
                    semana.push(<Dia key={currentDay} dia={currentDay} eventos={eventosDoDia} />);
                    dia++;
                } else {
                    semana.push(<Dia key={`empty-${i}-${j}`} />);
                }
            }
            dias.push(<div className="week" key={i}>{semana}</div>);
        }
        return dias;
    };

    const nomeMes = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(new Date(ano, mes));

    const avancarMes = () => {
        if (mes === 11) {
            setMes(0);
            setAno(ano + 1);
        } else {
            setMes(mes + 1);
        }
    };

    const voltarMes = () => {
        if (mes === 0) {
            setMes(11);
            setAno(ano - 1);
        } else {
            setMes(mes - 1);
        }
    };

    return (
        <div className='calendar-geral'>
            <div className="calendar-container">
                <div className="calendar-header">{ano} - {nomeMes.toUpperCase()}</div>
                <div className="calendar">
                    <div className="week">
                        <div className="day">DOM</div>
                        <div className="day">SEG</div>
                        <div className="day">TER</div>
                        <div className="day">QUA</div>
                        <div className="day">QUI</div>
                        <div className="day">SEX</div>
                        <div className="day">SÁB</div>
                    </div>
                    {criarDias()}
                </div>
            </div>
            <div className="calendar-controls">
                <button onClick={voltarMes}>{"<"}</button>
                <button onClick={avancarMes}>{">"}</button>
            </div>
        </div>
    );
};

export default Calendario;
